import React, { useState, useEffect, useRef } from "react";
import styles from "./AboutUsHero.module.css";
import Cards from "./memory/Cards";

import BizagiLogo from "../../assets/logos/BizagiLogo.png";
import CorelDrawLogo from "../../assets/logos/CorelDrawLogo.png";
import FigmaLogo from "../../assets/logos/FigmaLogo.png";
import GoogleAdsLogo from "../../assets/logos/GoogleAdsLogo.png";
import ReactLogo from "../../assets/logos/ReactLogo.png";
import VueLogo from "../../assets/logos/VueLogo.png";

const uniqueCardsArray = [
  {
    type: "React",
    image: ReactLogo,
  },
  {
    type: "Vue",
    image: VueLogo,
  },
  {
    type: "Bizagi",
    image: BizagiLogo,
  },
  {
    type: "GoogleAds",
    image: GoogleAdsLogo,
  },
  {
    type: "Figma",
    image: FigmaLogo,
  },
  {
    type: "CorelDraw",
    image: CorelDrawLogo,
  },
];

function shuffleCards(array) {
  const length = array.length;
  for (let i = length; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * i);
    const currentIndex = i - 1;
    const temp = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temp;
  }
  return array;
}

function AboutUsHero() {
  const [cards, setCards] = useState(() =>
    shuffleCards(uniqueCardsArray.concat(uniqueCardsArray))
  );
  const [openCards, setOpenCards] = useState([]);
  const [clearedCards, setClearedCards] = useState({});
  const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);
  const [moves, setMoves] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [bestScore, setBestScore] = useState(
    JSON.parse(localStorage.getItem("bestScore")) || Number.POSITIVE_INFINITY
  );

  const timeout = useRef(null);

  const disable = () => {
    setShouldDisableAllCards(true);
  };
  const enable = () => {
    setShouldDisableAllCards(false);
  };

  const checkCompletion = () => {
    if (Object.keys(clearedCards).length === uniqueCardsArray.length) {
      setShowModal(true);
      const highScore = Math.min(moves, bestScore);
      setBestScore(highScore);
      localStorage.setItem("bestScore", highScore);
    }
  };

  const evaluate = () => {
    const [first, second] = openCards;
    enable();
    if (cards[first].type === cards[second].type) {
      setClearedCards((prev) => ({ ...prev, [cards[first].type]: true }));
      setOpenCards([]);
      return;
    }
    // This is to flip the cards back after 500ms duration
    timeout.current = setTimeout(() => {
      setOpenCards([]);
    }, 500);
  };
  const handleCardClick = (index) => {
    if (openCards.length === 1) {
      setOpenCards((prev) => [...prev, index]);
      setMoves((moves) => moves + 1);
      disable();
    } else {
      clearTimeout(timeout.current);
      setOpenCards([index]);
    }
  };

  useEffect(() => {
    let timeout = null;
    if (openCards.length === 2) {
      timeout = setTimeout(evaluate, 300);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [openCards]);

  useEffect(() => {
    checkCompletion();
  }, [clearedCards]);
  const checkIsFlipped = (index) => {
    return openCards.includes(index);
  };

  const checkIsInactive = (card) => {
    return Boolean(clearedCards[card.type]);
  };

  const handleRestart = () => {
    setOpenCards([]);
    setShowModal(false);
    setClearedCards({});
    setMoves(0);
    setShouldDisableAllCards(false);
    // set a shuffled deck of cards
    setTimeout(() => {
      setCards(shuffleCards(uniqueCardsArray.concat(uniqueCardsArray)));
    }, 100);
  };

  const handleReveal = () => {
    setOpenCards([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
  };

  return (
    <React.Fragment>
      <section className={styles.container}>
        <div>
          <h1 className={styles.heading}>
            <span className={styles.animation_h1}>W</span>
            <span className={styles.animation_h1}>e</span>
            <span className={styles.animation_h1}> </span>
            <span className={styles.animation_h1}>M</span>
            <span className={styles.animation_h1}>a</span>
            <span className={styles.animation_h1}>k</span>
            <span className={styles.animation_h1}>e</span>
            <span className={styles.animation_h1}> </span>
            <span className={styles.animation_h1}>W</span>
            <span className={styles.animation_h1}>h</span>
            <span className={styles.animation_h1}>a</span>
            <span className={styles.animation_h1}>t</span>
            <span className={styles.animation_h1}> </span>
            <span className={styles.animation_h1}>I</span>
            <span className={styles.animation_h1}>s</span>
            <span className={styles.animation_h1}> </span>
            <span className={styles.animation_h1}>Y</span>
            <span className={styles.animation_h1}>e</span>
            <span className={styles.animation_h1}>t</span>
            <span className={styles.animation_h1}> </span>
            <span className={styles.animation_h1}>T</span>
            <span className={styles.animation_h1}>o</span>
            <span className={styles.animation_h1}> </span>
            <span className={styles.animation_h1}>C</span>
            <span className={styles.animation_h1}>o</span>
            <span className={styles.animation_h1}>m</span>
            <span className={styles.animation_h1}>e</span>
          </h1>
          <p className={styles.excerpt}>
            We drive growth for your
            <br />
            business making your brand
            <br />
            more compelling online
          </p>
        </div>
        <div className={styles.memory_grid}>
          <span className={styles.memory_title}>
            Play A Little Memory Game
            <br />
            And Discover Some Of Our Tehnologies
          </span>
          <div className={styles.button_reset}>
            <button
              className={`${styles.memory_button}`}
              onClick={handleRestart}
            >
              PLAY AGAIN
            </button>
          </div>
          <div className={styles.button_reveal}>
            <button
              className={`${styles.memory_button}`}
              onClick={handleReveal}
            >
              REVEAL ALL
            </button>
          </div>
          <div className={styles.memory_info}>
            <div className={styles.memory_moves}>
              <span>Moves:</span>
              <span>{moves}</span>
            </div>
            {localStorage.getItem("bestScore") && (
              <div className={styles.memory_high_score}>
                <span>Best:</span>
                <span>{bestScore}</span>
              </div>
            )}
          </div>
          {cards.map((card, index) => {
            return (
              <Cards
                key={index}
                card={card}
                index={index}
                isDisabled={shouldDisableAllCards}
                isInactive={checkIsInactive(card)}
                isFlipped={checkIsFlipped(index)}
                onClick={handleCardClick}
              />
            );
          })}
        </div>
      </section>
    </React.Fragment>
  );
}

export default AboutUsHero;
