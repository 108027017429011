import React from "react";
import { Link } from "react-router-dom";
import styles from "./OffersGraphic.module.css";

function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={styles.check_icon}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
    </svg>
  );
}

function Starter() {
  return (
    <React.Fragment>
      <li className={styles.list_item}>
        <CheckIcon></CheckIcon>
        <h4 className={styles.list_item_name}>Simple Logo Design</h4>
      </li>
      <li className={styles.list_item}>
        <CheckIcon></CheckIcon>
        <h4 className={styles.list_item_name}>3D Logo Visualization</h4>
      </li>
      <li className={styles.list_item}>
        <CheckIcon></CheckIcon>
        <h4 className={styles.list_item_name}>Business Cards Visualization</h4>
      </li>
      <li className={styles.list_item}>
        <CheckIcon></CheckIcon>
        <h4 className={styles.list_item_name}>Logo Documentation</h4>
      </li>
      <li className={styles.list_item}>
        <CheckIcon></CheckIcon>
        <h4 className={styles.list_item_name}>Brand Style Guides</h4>
      </li>
      <li className={styles.list_item}>
        <CheckIcon></CheckIcon>
        <h4 className={styles.list_item_name}>Social Media Graphics</h4>
      </li>
    </React.Fragment>
  );
}

function Advanced() {
  return (
    <React.Fragment>
      <li className={styles.list_item}>
        <CheckIcon></CheckIcon>
        <h4 className={styles.list_item_name}>Character Modeling</h4>
      </li>
      <li className={styles.list_item}>
        <CheckIcon></CheckIcon>
        <h4 className={styles.list_item_name}>Backgrounds And Environments</h4>
      </li>
      <li className={styles.list_item}>
        <CheckIcon></CheckIcon>
        <h4 className={styles.list_item_name}>Props And Objects</h4>
      </li>
      <li className={styles.list_item}>
        <CheckIcon></CheckIcon>
        <h4 className={styles.list_item_name}>Character Animation</h4>
      </li>
      <li className={styles.list_item}>
        <CheckIcon></CheckIcon>
        <h4 className={styles.list_item_name}>Menu UI/UX Design</h4>
      </li>
      <li className={styles.list_item}>
        <CheckIcon></CheckIcon>
        <h4 className={styles.list_item_name}>Cutscenes Design</h4>
      </li>
    </React.Fragment>
  );
}
function Premium() {
  return (
    <React.Fragment>
      <li className={styles.list_item}>
        <CheckIcon></CheckIcon>
        <h4 className={styles.list_item_name}>Building Information Modeling</h4>
      </li>
      <li className={styles.list_item}>
        <CheckIcon></CheckIcon>
        <h4 className={styles.list_item_name}>Layout Drafting</h4>
      </li>
      <li className={styles.list_item}>
        <CheckIcon></CheckIcon>
        <h4 className={styles.list_item_name}>Schedules & Calculations</h4>
      </li>
      <li className={styles.list_item}>
        <CheckIcon></CheckIcon>
        <h4 className={styles.list_item_name}>Interior Design</h4>
      </li>
      <li className={styles.list_item}>
        <CheckIcon></CheckIcon>
        <h4 className={styles.list_item_name}>Landscape Design</h4>
      </li>
      <li className={styles.list_item}>
        <CheckIcon></CheckIcon>
        <h4 className={styles.list_item_name}>Visualization & Animation</h4>
      </li>
    </React.Fragment>
  );
}

function OffersGraphic() {
  return (
    <React.Fragment>
      <div className={styles.offer_wrapper}>
        <h2 className={styles.offer_title}>
          With Us You Pay
          <br />
          Only What You Need
          <br />
          <span className={styles.offer_title_span}>And You Can Lease It</span>
        </h2>
        <Link to="/contact-us" className={styles.offer_link}>
          <div className={styles.offer_link_wrapper}>
            <span className={styles.offer_link_wrapper_first}>
              THIS IS AWESOME
            </span>
            <span className={styles.offer_link_wrapper_second}>
              GIVE ME AN OFFER
            </span>
          </div>
        </Link>
        <article>
          <div className={styles.card}>
            <h3 className={styles.card_title}>BUSINESS</h3>
            <p className={styles.card_excerpt}>
              If you starting your own business and want to show it to the World
            </p>
            <div className={styles.card_list_wrapper}>
              <ul className={styles.card_list}>
                <Starter></Starter>
              </ul>
            </div>
            <div className={styles.card_price}>
              <span className={styles.starting_at}>STARTING AT</span>
              <span className={styles.price}>
                500€<span className={styles.per_month}>per month</span>
              </span>
            </div>
            <span className={styles.min_month}>(min. 12 months)</span>
          </div>
        </article>
        <article>
          <div className={styles.card}>
            <h3 className={styles.card_title}>GAMING</h3>
            <p className={styles.card_excerpt}>
              For those who already have a defined business logic but want to
              improve
            </p>
            <div className={styles.card_list_wrapper}>
              <ul className={styles.card_list}>
                <Advanced></Advanced>
              </ul>
            </div>
            <div className={styles.card_price}>
              <span className={styles.starting_at}>STARTING AT</span>
              <span className={styles.price}>
                1000€<span className={styles.per_month}>per month</span>
              </span>
            </div>
            <span className={styles.min_month}>(min. 12 months)</span>
          </div>
        </article>
        <article>
          <div className={styles.card}>
            <h3 className={styles.card_title}>ARHITECTURE</h3>
            <p className={styles.card_excerpt}>
              For large scale business with ambition to become leaders in their
              field
            </p>
            <div className={styles.card_list_wrapper}>
              <ul className={styles.card_list}>
                <Premium></Premium>
              </ul>
            </div>
            <div className={styles.card_price}>
              <span className={styles.starting_at}>STARTING AT</span>
              <span className={styles.price}>
                1000€<span className={styles.per_month}>per month</span>
              </span>
            </div>
            <span className={styles.min_month}>(min. 12 months)</span>
          </div>
        </article>
      </div>
    </React.Fragment>
  );
}

export default OffersGraphic;
