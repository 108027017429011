import React from "react";
import AboutUsLink from "./AboutUsLink";
import styles from "./AboutUsPortfolio.module.css";

function AboutUsPortfolio() {
  return (
    <React.Fragment>
      <section className={styles.container}>
        <div className={styles.portfolio_wrapper}>
          <div className={styles.heading_wrapper}>
            <h2 className={styles.portfolio_h2}>Our Portfolio</h2>
          </div>
          <div className={styles.position}>
            <ul className={styles.link_wrapper}>
              <AboutUsLink></AboutUsLink>
            </ul>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default AboutUsPortfolio;
