import React, { useEffect } from "react";
import styles from "./Menu.module.css";
import WebsiteCard from "../hero/cards/WebsiteCard";
import DesignCard from "../hero/cards/DesignCard";
import MarketingCard from "../hero/cards/MarketingCard";
import BussinesCard from "../hero/cards/BussinesCard";
import logo_menu from "../../../assets/logo_menu.png";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1344 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isLandscapeTablet = useMediaQuery({ maxWidth: 1343 });
  return isLandscapeTablet ? children : null;
};

const Menu = function (props) {
  const { pathname } = useLocation();

  useEffect(() => {
    props.setMenu(false);
  }, [pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <React.Fragment>
      <Desktop>
        <nav
          className={`${styles.menu} ${
            props.isMenuOpen ? styles.menu_open : ""
          }`}
        >
          <div className={styles.logo_menu}>
            <Link to="/">
              <img
                src={logo_menu}
                alt="Redsync simple logo without moto"
                className={styles.logo_menu_img}
              />
            </Link>
          </div>
          <ul className={styles.menu_list}>
            <li className={`${styles.menu_list_li} `}>
              <Link
                to="/website-development"
                className={`${
                  pathname === "/website-development" ? styles.active : ""
                } ${styles.menu_list_item_link}`}
              >
                <p>
                  Dynamic <strong>Website</strong> Development
                </p>
              </Link>
              <div className={styles.cards}>
                <WebsiteCard></WebsiteCard>
              </div>
            </li>
            <li>
              <Link
                to="/digital-marketing"
                className={`${
                  pathname === "/digital-marketing" ? styles.active : ""
                } ${styles.menu_list_item_link}`}
              >
                <p>
                  Digital <strong>Marketing</strong> Managment
                </p>
              </Link>
              <div className={styles.cards}>
                <MarketingCard></MarketingCard>
              </div>
            </li>
            <li>
              <Link
                to="/graphic-design"
                className={`${
                  pathname === "/graphic-design" ? styles.active : ""
                } ${styles.menu_list_item_link}`}
              >
                <p>
                  Graphic <strong>Design</strong> Services
                </p>
              </Link>
              <div className={styles.cards}>
                <DesignCard></DesignCard>
              </div>
            </li>
            <li>
              <Link
                to="/business-analysis"
                className={`${
                  pathname === "/business-analysis" ? styles.active : ""
                } ${styles.menu_list_item_link}`}
              >
                <p>
                  Business <strong>Analysis</strong> and{" "}
                  <strong>Consulting</strong>
                </p>
              </Link>
              <div className={styles.cards}>
                <BussinesCard></BussinesCard>
              </div>
            </li>
            <li>
              <ul className={styles.menu_list_footer}>
                <li>
                  <Link
                    to="/blog"
                    className={`${pathname === "/blog" ? styles.active1 : ""} ${
                      styles.menu_list_footer_item
                    }`}
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about-us"
                    className={`${
                      pathname === "/about-us" ? styles.active1 : ""
                    } ${styles.menu_list_footer_item}`}
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact-us"
                    className={`${
                      pathname === "/contact-us" ? styles.active1 : ""
                    } ${styles.menu_list_footer_item}`}
                  >
                    Contact Us
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/jobs"
                    className={`${pathname === "/jobs" ? styles.active1 : ""} ${
                      styles.menu_list_footer_item
                    }`}
                  >
                    Jobs
                  </Link>
                </li> */}
              </ul>
            </li>
          </ul>

          <div
            className={`${styles.menu_button_close}`}
            onClick={props.onClick}
          >
            <svg
              className={styles.menu_icon}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>

            <span>close</span>
          </div>
        </nav>
      </Desktop>
      <Tablet>
        <nav
          className={`${styles.menu} ${
            props.isMenuOpen ? styles.menu_open : ""
          }`}
        >
          <ul className={styles.menu_list}>
            <li className={`${styles.menu_list_li} `}>
              <Link
                to="/website-development"
                className={`${
                  pathname === "/website-development" ? styles.active : ""
                } ${styles.menu_list_item_link}`}
              >
                <p>
                  Dynamic <strong>Website</strong> Development
                </p>
              </Link>
              <div className={styles.cards}>
                <WebsiteCard></WebsiteCard>
              </div>
            </li>
            <li>
              <Link
                to="/digital-marketing"
                className={`${
                  pathname === "/digital-marketing" ? styles.active : ""
                } ${styles.menu_list_item_link}`}
              >
                <p>
                  Digital <strong>Marketing</strong> Managment
                </p>
              </Link>
              <div className={styles.cards}>
                <MarketingCard></MarketingCard>
              </div>
            </li>
            <li>
              <Link
                to="/graphic-design"
                className={`${
                  pathname === "/graphic-design" ? styles.active : ""
                } ${styles.menu_list_item_link}`}
              >
                <p>
                  Graphic <strong>Design</strong> Services
                </p>
              </Link>
              <div className={styles.cards}>
                <DesignCard></DesignCard>
              </div>
            </li>
            <li>
              <Link
                to="/business-analysis"
                className={`${
                  pathname === "/business-analysis" ? styles.active : ""
                } ${styles.menu_list_item_link}`}
              >
                <p>
                  Business <strong>Analysis</strong> and{" "}
                  <strong>Consulting</strong>
                </p>
              </Link>
              <div className={styles.cards}>
                <BussinesCard></BussinesCard>
              </div>
            </li>
            <li>
              <ul className={styles.menu_list_footer}>
                <div className={styles.logo_menu}>
                  <Link to="/">
                    <img
                      src={logo_menu}
                      alt="Redsync simple logo without moto"
                      className={styles.logo_menu_img}
                    />
                  </Link>
                </div>
                <li>
                  <Link
                    to="/blog"
                    className={`${pathname === "/blog" ? styles.active1 : ""} ${
                      styles.menu_list_footer_item
                    }`}
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about-us"
                    className={`${
                      pathname === "/about-us" ? styles.active1 : ""
                    } ${styles.menu_list_footer_item}`}
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact-us"
                    className={`${
                      pathname === "/contact-us" ? styles.active1 : ""
                    } ${styles.menu_list_footer_item}`}
                  >
                    Contact Us
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/jobs"
                    className={`${pathname === "/jobs" ? styles.active1 : ""} ${
                      styles.menu_list_footer_item
                    }`}
                  >
                    Jobs
                  </Link>
                </li> */}
              </ul>
            </li>
          </ul>

          <div
            className={`${styles.menu_button_close}`}
            onClick={props.onClick}
          >
            <svg
              className={styles.menu_icon}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
            <span>close</span>
          </div>
        </nav>
      </Tablet>
    </React.Fragment>
  );
};

export default Menu;
