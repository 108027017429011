import React from "react";
import styles from "./AboutUsGoals.module.css";
import Tilt from "react-parallax-tilt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faBullseye, faGem } from "@fortawesome/free-solid-svg-icons";

function AboutUsGoals() {
  return (
    <React.Fragment>
      <section className={styles.container}>
        <h2 className={styles.heading_h2}>Let's Grow Together</h2>
        <div className={`${styles.vision_card} ${styles.card}`}>
          <Tilt scale={1.05}>
            <h2 className={styles.heading}>
              <span className={styles.pre}>OUR</span>
              <br />
              <span className={styles.name}>VISION</span>
            </h2>
          </Tilt>
          <p className={styles.card_desc}>
            Our Vision is to become one of the most popular Digital Agency in
            Europe.
          </p>
          <FontAwesomeIcon
            icon={faEye}
            className={`${styles.icon} ${styles.eye}`}
          />
        </div>
        <div className={`${styles.mission_card} ${styles.card}`}>
          <Tilt scale={1.05}>
            <h2 className={styles.heading}>
              <span className={styles.pre}>OUR</span>
              <br />
              <span className={styles.name}>MISSION</span>
            </h2>
          </Tilt>
          <p className={styles.card_desc}>
            Our Mission is enabling every company to take their business to
            another level.
          </p>
          <FontAwesomeIcon icon={faBullseye} className={`${styles.icon} `} />
        </div>
        <div className={`${styles.goal_card} ${styles.card}`}>
          <Tilt scale={1.05}>
            <h2 className={styles.heading}>
              <span className={styles.pre}>OUR</span>
              <br />
              <span className={styles.name}>GOALS</span>
            </h2>
          </Tilt>
          <p className={styles.card_desc}>
            Our Goals are to grow along with clients and providing them a
            partner experience they deserve.
          </p>
          <FontAwesomeIcon icon={faGem} className={styles.icon} />
        </div>
      </section>
    </React.Fragment>
  );
}

export default AboutUsGoals;
