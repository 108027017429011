import React, { useState } from "react";
import Particles from "react-tsparticles";
import particlesConfig from "../config/particlesConfig";
import MenuButton from "../components/home-page/header/MenuButton";
import Menu from "../components/home-page/header/Menu";
import { Link } from "react-router-dom";
import logo from ".././assets/logo192.png";
import GetAnEstimate from "../components/helpers/GetAnEstimate";
import HeroWebsite from "../components/graphic-design/hero/HeroGraphic";
import StagesWebsite from "../components/graphic-design/stages/StagesGraphic";
import PortfolioWebsite from "../components/graphic-design/portfolio/PortfolioGraphic";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import OffersGraphic from "../components/graphic-design/offer/OffersGraphic";
import { useMediaQuery } from "react-responsive";
import home_page from "../assets/og_images/home_page.jpg";
import CookiesModal from "../components/helpers/CookiesModal";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1700 });
  return isDesktop ? children : null;
};

const queryClient = new QueryClient();

const GraphicDesignServices = function (props) {
  const [isMenuOpen, setMenu] = useState(false);

  const handleMenu = () => {
    setMenu((prevState) => !prevState);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <CookiesModal></CookiesModal>
      <React.Fragment>
        <HelmetProvider>
          <Helmet>
            <meta
              property="og:title"
              content="REDsync - Digital Agency | We Make What Is Yet To Come"
            />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={home_page} />
            <meta
              property="og:description"
              content="Get The Best From Your Digital Agency. Sit back and enjoy your coffee, while we translate your hopes and plans into digital reality."
            />
            <meta
              property="og:image:alt"
              content="Redsync Logo And Moto With Offer Cards For Dynamic Website Development, Digital Marketing Managment, Graphic Design Services and Business Analysis and Consulting"
            />

            <meta property="og:site_name" content="REDsync - Digital Agency" />
          </Helmet>
          <div style={{ position: "absolute" }}>
            <Particles height="100vh" width="100vw" params={particlesConfig} />
          </div>
          <header className="header">
            <MenuButton onClick={handleMenu}></MenuButton>
            <Menu
              isMenuOpen={isMenuOpen}
              onClick={handleMenu}
              setMenu={setMenu}
            ></Menu>
            <Link to="/">
              <img
                src={logo}
                alt="Redsync Logo Without Moto"
                className="logo_homepage"
              />
            </Link>

            <GetAnEstimate></GetAnEstimate>
          </header>
          <main className="/animation_on_load">
            <section>
              <HeroWebsite></HeroWebsite>
            </section>
            <section>
              <OffersGraphic></OffersGraphic>
            </section>
            <section>
              <Desktop>
                <StagesWebsite></StagesWebsite>
              </Desktop>
            </section>
            <section>
              <PortfolioWebsite></PortfolioWebsite>
            </section>
          </main>
        </HelmetProvider>
      </React.Fragment>
    </QueryClientProvider>
  );
};

export default GraphicDesignServices;
