import React from "react";
import PortfolioLink from "./PortfolioLink";
import styles from "./PortfolioBusiness.module.css";

const PortfolioWebsite = function (props) {
  return (
    <React.Fragment>
      <div className={styles.portfolio_wrapper}>
        <div className={styles.heading_wrapper}>
          <h2 className={styles.portfolio_h2}>Our Portfolio</h2>
        </div>
        <div className={styles.position}>
          <ul className={styles.link_wrapper}>
            <PortfolioLink></PortfolioLink>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PortfolioWebsite;
