import React, { useEffect } from "react";

import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import DynamicWebsiteDevelopment from "./pages/DynamicWebsiteDevelopment";
import DigitalMarketingManagment from "./pages/DigitalMarketingManagment";
import GraphicDesignServices from "./pages/GraphicDesignServices";
import BusinessAnalysis from "./pages/BusinessAnalysis";

import Blog from "./pages/Blog";
import Post from "./components/blog/Post";

import NotFound from "./pages/NotFound";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
// import Jobs from "./pages/Jobs";
// import Job from "./components/jobs/Job";
// import CookiesModal from "./components/helpers/CookiesModal";

const App = function () {
  useEffect(() => {
    const animationOnLoad = document.querySelectorAll(".animation_on_load");

    const htmlElement = document.getElementsByTagName("html");
    htmlElement[0].style.display = "block";

    setTimeout(() => {
      for (let i = 0; i < animationOnLoad.length; i++) {
        animationOnLoad[i].classList.remove("animation_on_load");
      }
    }, 2000);
  });

  return (
    <Router>
      <Routes>
        <Route path="*" element={<NotFound></NotFound>}></Route>
        <Route path="/" element={<HomePage></HomePage>}></Route>
        <Route
          path="/website-development"
          element={<DynamicWebsiteDevelopment></DynamicWebsiteDevelopment>}
        ></Route>
        <Route
          path="/digital-marketing"
          element={<DigitalMarketingManagment></DigitalMarketingManagment>}
        ></Route>
        <Route
          path="/graphic-design"
          element={<GraphicDesignServices></GraphicDesignServices>}
        ></Route>
        <Route
          path="/business-analysis"
          element={<BusinessAnalysis></BusinessAnalysis>}
        ></Route>

        <Route path="/blog" element={<Blog></Blog>}></Route>
        <Route path="/blog/web-development" element={<Blog></Blog>}></Route>
        <Route path="/blog/graphic-design" element={<Blog></Blog>}></Route>
        <Route path="/blog/digital-marketing" element={<Blog></Blog>}></Route>
        <Route path="/blog/business-consulting" element={<Blog></Blog>}></Route>
        <Route path="/blog/post/:slug" element={<Post></Post>}></Route>
        <Route path="/about-us" element={<AboutUs></AboutUs>}></Route>
        <Route path="/contact-us" element={<ContactUs></ContactUs>}></Route>
        {/* <Route path="/jobs" element={<Jobs></Jobs>}></Route>
        <Route path="/jobs/job/:slug" element={<Job></Job>}></Route> */}
      </Routes>
    </Router>
  );
};

export default App;
