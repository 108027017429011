import React from "react";
import Description from "./cards/Description";
import styles from "./Section1.module.css";
import { Link } from "react-router-dom";
import Tilt from "react-parallax-tilt";
import { ReactComponent as CubeIcon } from "../../../assets/cube_icon.svg";
import { useMediaQuery } from "react-responsive";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 944 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 704, maxWidth: 943 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isTablet = useMediaQuery({ maxWidth: 703 });
  return isTablet ? children : null;
};

const Section1 = function (props) {
  return (
    <React.Fragment>
      <Desktop>
        <div className={styles.section_section1_main}>
          <Tilt scale={1.1} tiltMaxAngleX={5} tiltMaxAngleY={5}>
            <h2 className={styles.section_section1_h2}>
              Get The Best
              <br />
              From Your
              <br />
              Digital Agency
            </h2>
          </Tilt>
          <div className={styles.p_helper}>
            <Tilt scale={1.1} tiltMaxAngleX={10} tiltMaxAngleY={10}>
              <p className={styles.section_section1_p}>
                We drive growth for your
                <br />
                business making your brand
                <br />
                more compelling online
              </p>
            </Tilt>
          </div>
          <div className={styles.link_wrapper}>
            <Link to="/about-us" style={{ textDecoration: "none" }}>
              <Tilt scale={1.1} tiltMaxAngleX={0} tiltMaxAngleY={20}>
                <div className={styles.section_section1_a}>
                  <span>Our Services</span>
                </div>
              </Tilt>
            </Link>
          </div>
        </div>
        <div className={styles.section_section1_cards}>
          <div className={styles.cube_drag_text}>
            <span>Drag</span>
            <CubeIcon className={styles.cube_icon} />
            <span>To Rotate</span>
          </div>
          <Description></Description>
        </div>
      </Desktop>
      <Tablet>
        <div className={styles.section_section1_main}>
          <Tilt scale={1.05} tiltMaxAngleX={5} tiltMaxAngleY={5}>
            <h2 className={styles.section_section1_h2}>
              Get The Best
              <br />
              From Your
              <br />
              Digital Agency
            </h2>
          </Tilt>
          <div className={styles.section_section1_cards}>
            <div className={styles.cube_drag_text}>
              <span>Drag</span>
              <CubeIcon className={styles.cube_icon} />
              <span>To Rotate</span>
            </div>
            <Description></Description>
          </div>
          <div className={styles.p_helper}>
            <Tilt scale={1.1} tiltMaxAngleX={10} tiltMaxAngleY={10}>
              <p className={styles.section_section1_p}>
                We drive growth for your
                <br />
                business making your brand
                <br />
                more compelling online
              </p>
            </Tilt>
          </div>
          <div className={styles.link_wrapper}>
            <Link to="/about-us" style={{ textDecoration: "none" }}>
              <Tilt scale={1.1} tiltMaxAngleX={0} tiltMaxAngleY={20}>
                <div className={styles.section_section1_a}>
                  <span>Our Services</span>
                </div>
              </Tilt>
            </Link>
          </div>
        </div>
      </Tablet>
      <Mobile>
        <div className={styles.section_section1_main}>
          <h2 className={styles.section_section1_h2}>
            <Tilt scale={1.05} tiltMaxAngleX={5} tiltMaxAngleY={5}>
              Get The Best
              <br />
              From Your
              <br />
              Digital Agency
            </Tilt>
          </h2>
          <div className={styles.section_section1_cards}>
            <div className={styles.cube_drag_text}>
              <span>Drag</span>
              <CubeIcon className={styles.cube_icon} />
              <span>To Rotate</span>
            </div>
            <Description></Description>
          </div>
          <div className={styles.p_helper}>
            <Tilt scale={1.1} tiltMaxAngleX={10} tiltMaxAngleY={10}>
              <p className={styles.section_section1_p}>
                We drive growth for your
                <br />
                business making your brand
                <br />
                more compelling online
              </p>
            </Tilt>
          </div>
          <div className={styles.link_wrapper}>
            <Link to="/about-us" style={{ textDecoration: "none" }}>
              <Tilt scale={1.1} tiltMaxAngleX={0} tiltMaxAngleY={20}>
                <div className={styles.section_section1_a}>
                  <span>Our Services</span>
                </div>
              </Tilt>
            </Link>
          </div>
        </div>
      </Mobile>
    </React.Fragment>
  );
};

export default Section1;
