import React, { useEffect, useRef } from "react";
import styles from "./StagesWebsite.module.css";
import { useInViewport } from "react-in-viewport";

const StagesWebsite = function (props) {
  let options = {
    treshold: [0, 0.2, 0.4, 0.6, 0.8, 1],
  };

  const svgRef = useRef();
  const myRef = useRef();
  const { inViewport } = useInViewport(myRef, options, props);

  useEffect(() => {
    let path = document.querySelector(".path");
    let pathLenght = path.getTotalLength();
    path.style.strokeDasharray = pathLenght + " " + pathLenght;
    path.style.strokeDashoffset = pathLenght;
    const el = svgRef.current;
    window.addEventListener("scroll", () => {
      if (!inViewport) {
        el.style.display = "none";
      }
      if (inViewport) {
        el.style.display = "inline-block";
        let scrollPercentage =
          (document.documentElement.scrollTop -
            2 * document.documentElement.clientHeight) /
          (document.documentElement.scrollHeight -
            2 * document.documentElement.clientHeight);

        let drawLength = pathLenght * scrollPercentage * 2;
        if (drawLength < pathLenght && drawLength > 0) {
          path.style.strokeDashoffset = pathLenght - drawLength;
        }
      }
    });
  }, [inViewport]);

  return (
    <React.Fragment>
      <div className={styles.stages_website_wrapper} ref={myRef}>
        <div className={styles.stay_on_scroll}>
          <div className={styles.line_container}>
            <svg
              ref={svgRef}
              className={styles.svg_path_website}
              fill="none"
              viewBox="0 0 1413 537"
              preserveAspectRatio="xMidYMax meet"
            >
              <path
                className="path"
                d="M58 269.5C57.6667 260.333 51.7 242 30.5 242C9.3 242 3.33333 260.333 3 269.5C3 278.667 8.5 297 30.5 297C35.3333 296.667 45.9 294.7 49.5 289.5L70 309V498.514H256H272L286 516.014V529.514C287.833 532.347 293.2 536.314 300 529.514V516.014L314.5 498.514C319.333 486.014 321.7 461.014 292.5 461.014C292.5 459.814 292.5 261 292.5 181H422.5H500L492 188.5L481.5 213.5L507 205L554.5 157.5C554.5 155.667 554.5 151.8 554.5 151C554.5 150.2 547.5 143.333 544 140V3.50001L824 3.5H836C839.667 3.33333 848.1 4.6 852.5 11L863 21.5L860 24.5L867.5 32L870.5 29L879 37.5L864 52.5L855 43.5L858.5 40.0001L851.5 33L813 71.5L802 60.5V465.5H1138.5V485C1144.67 485.167 1155.9 489.4 1151.5 505L1164.5 512.5C1168.67 507.833 1179.4 501.3 1189 512.5L1202.5 505C1200.67 499.333 1200.3 487.2 1213.5 484V468.5C1208.5 467.333 1199 461.8 1201 449L1188 441C1185.17 444.667 1176.9 449.8 1166.5 441V108H1340V96C1341.83 87.5 1350.9 70.5 1372.5 70.5C1394.1 70.5 1403.17 87.5 1405 96C1406.67 96.3333 1410 97.6 1410 100C1410 102.4 1410 108.667 1410 111.5C1409.5 113.167 1410 111.5 1405 114V124.5C1404.5 127.667 1401.4 132.5 1397 132.5C1394.26 132.5 1387.25 132.694 1380.5 132.84C1376.4 132.929 1372.4 133 1369.5 133V127.5C1370.83 126 1374.2 123.9 1377 127.5V132.84"
                stroke="#fff"
                strokeWidth="7"
              />
            </svg>
          </div>

          <h2 className={styles.stages_h2}>Stages of Website Development</h2>
          <div className={styles.stages_grid}>
            <div className={`${styles.card_main} ${styles.card1}`}>
              <span className={styles.stage_name}>research</span>
            </div>

            <div className={`${styles.card_main} ${styles.card2}`}>
              <span className={styles.stage_name}>prototyping</span>
            </div>

            <div className={`${styles.card_main} ${styles.card3}`}>
              <span className={styles.stage_name}>design</span>
            </div>

            <div className={`${styles.card_main} ${styles.card4}`}>
              <span className={styles.stage_name}>front & backend</span>
            </div>

            <div className={`${styles.card_main} ${styles.card5}`}>
              <span className={styles.stage_name}>seo</span>
            </div>

            <div className={`${styles.card_main} ${styles.card6}`}>
              <span className={styles.stage_name}>support & maintenance</span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StagesWebsite;
