import React from "react";
import Card from "./Card";
import { Link } from "react-router-dom";

const MarketingCard = function (props) {
  return (
    <React.Fragment>
      <Link to="/digital-marketing" style={{ textDecoration: "none" }}>
        <Card>
          <span>02</span>
          <h2>
            Digital
            <br />
            <strong>Marketing</strong>
            <br />
            Managment
          </h2>
          <h3>
            #seo
            <br />
            #audit
            <br />
            #marketing
          </h3>
        </Card>
      </Link>
    </React.Fragment>
  );
};
export default MarketingCard;
