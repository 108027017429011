import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useMediaQuery } from "react-responsive";
import HomePageDesktop from "./Desktop/HomePageDesktop";
import HomePageLandscapeTablet from "./LandscapeTablet/HomePageLandspaceTablet";
import HomePageMobile from "./Mobile/HomePageMobile";
import HomePageSmallerDesktop from "./SmallerDesktop/HomePageSmallerDesktop";
import HomePageSmallerTablet from "./SmallerTablet/HomePageSmallerTablet";
import HomePageTablet from "./Tablet/HomePageTablet";
import home_page from "../assets/og_images/home_page.jpg";
import CookiesModal from "../components/helpers/CookiesModal";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1700 });
  return isDesktop ? children : null;
};
const SmallerDesktop = ({ children }) => {
  const isSmallerDesktop = useMediaQuery({ minWidth: 1344, maxWidth: 1699 });
  return isSmallerDesktop ? children : null;
};
const LandscapeTablet = ({ children }) => {
  const isLandscapeTablet = useMediaQuery({ minWidth: 944, maxWidth: 1343 });
  return isLandscapeTablet ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 704, maxWidth: 943 });
  return isTablet ? children : null;
};
const SmallerTablet = ({ children }) => {
  const isSmallerTablet = useMediaQuery({ minWidth: 600, maxWidth: 703 });
  return isSmallerTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 599 });
  return isMobile ? children : null;
};

const HomePage = function (props) {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet>
          <meta
            property="og:title"
            content="REDsync - Digital Agency | We Make What Is Yet To Come"
          />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={home_page} />
          <meta
            property="og:description"
            content="Get The Best From Your Digital Agency. Sit back and enjoy your coffee, while we translate your hopes and plans into digital reality."
          />
          <meta
            property="og:image:alt"
            content="Redsync Logo And Moto With Offer Cards For Dynamic Website Development, Digital Marketing Managment, Graphic Design Services and Business Analysis and Consulting"
          />

          <meta property="og:site_name" content="REDsync - Digital Agency" />
        </Helmet>
        <CookiesModal></CookiesModal>
        <Desktop>
          <HomePageDesktop></HomePageDesktop>
        </Desktop>
        <SmallerDesktop>
          <HomePageSmallerDesktop></HomePageSmallerDesktop>
        </SmallerDesktop>
        <LandscapeTablet>
          <HomePageLandscapeTablet></HomePageLandscapeTablet>
        </LandscapeTablet>
        <Tablet>
          <HomePageTablet></HomePageTablet>
        </Tablet>
        <SmallerTablet>
          <HomePageSmallerTablet></HomePageSmallerTablet>
        </SmallerTablet>
        <Mobile>
          <HomePageMobile></HomePageMobile>
        </Mobile>
      </HelmetProvider>
    </React.Fragment>
  );
};

export default HomePage;
