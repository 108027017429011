import React from "react";
import styles from "./ModalLegal.module.css";

function ModalLegal(props) {
  return (
    <React.Fragment>
      <div className={styles.backdrop} onClick={props.onClick}></div>

      <div className={styles.open_modal}>
        <button onClick={props.onClick} className={styles.modal_button}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={styles.close}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <article className={styles.modal_content}>
          <h1 className={styles.modal_h1}>Legal Information</h1>
          <h2 className={styles.modal_h2}>Redsync j.d.o.o.</h2>
          <h2 className={styles.modal_h2}>
            Address: Veliki Komor 14B, 49251, Mače
          </h2>
          <h2 className={styles.modal_h2}>Email: info@redsync.studio</h2>
          <h2 className={styles.modal_h2}>Phone: +385 91 6126 544</h2>
          <h2 className={styles.modal_h2}>
            The company is registered at the Commercial Court in Zagreb under
            registration number (MBS) 081432096.
          </h2>
          <h2 className={styles.modal_h2}>
            Persons authorized to represent the company: Ivan Horvat
          </h2>
          <h2 className={styles.modal_h2}>
            Share capital of the company of 10,00 kn has been paid in full.
          </h2>
          <h2 className={styles.modal_h2}>
            Bank account: 2360000-1102988718, IBAN: HR7623600001102988718 SWIFT:
            ZABAHR2X, Zagrebačka banka d.d., Zagreb, Trg bana Josipa Jelačića
            10.
          </h2>
        </article>
      </div>
    </React.Fragment>
  );
}

export default ModalLegal;
