import React from "react";
import styled from "styled-components";
import styles from "./HorizontalScrollReferences.module.css";

import TopTimReferenca from "../../../assets/reference/TopTimReferenca.png";
import ApolonShopReferenca from "../../../assets/reference/ApolonShopReferenca.png";
import ApartmaniMihaelaReferenca from "../../../assets/reference/ApartmaniMihaelaReferenca.png";

import HorizontalScroll from "./horizontal-scroll-references";
import { useMediaQuery } from "react-responsive";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1344 });
  return isDesktop ? children : null;
};

const LandscapeTablet = ({ children }) => {
  const isLandscapeTablet = useMediaQuery({ maxWidth: 1343 });
  return isLandscapeTablet ? children : null;
};

const Main = styled.main`
  grid-column: 2;
`;

const HorizontalSection = styled.section`
  position: relative;
  width: 100%;
  min-height: 100vh;
`;

const CardsContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 24rem;
  padding: 10rem 0 0 0;
  @media (max-width: 1699px) {
    padding-left: 16rem;
  }
`;

const HorizontalScrollReferences = () => (
  <>
    <Desktop>
      <Main>
        <HorizontalSection>
          <HorizontalScroll>
            <CardsContainer>
              <a
                href="https://apolonshop.com/"
                target="_blank"
                rel="noreferrer"
                className={styles.list_of_references}
              >
                <img
                  src={ApolonShopReferenca}
                  alt="Reference for Apolon Shop - Internet Trgovina"
                  className={styles.reference_img}
                  height="100%"
                />
              </a>
              <a
                href="http://apartmanimihaela.com/"
                target="_blank"
                rel="noreferrer"
                className={styles.list_of_references}
              >
                <img
                  src={ApartmaniMihaelaReferenca}
                  alt="Reference for Apartmani Mihaela"
                  className={styles.reference_img}
                  height="100%"
                />
              </a>
              <a
                href="https://top-tim.com/"
                target="_blank"
                rel="noreferrer"
                className={styles.list_of_references}
              >
                <img
                  src={TopTimReferenca}
                  alt="Reference for Top Tim - Internet Trgovina"
                  className={styles.reference_img}
                  height="100%"
                />
              </a>
            </CardsContainer>
          </HorizontalScroll>
        </HorizontalSection>
      </Main>
    </Desktop>
    <LandscapeTablet>
      <div className={styles.reference_container}>
        <a
          href="https://apolonshop.com/"
          target="_blank"
          rel="noreferrer"
          className={styles.list_of_references}
        >
          <img
            src={ApolonShopReferenca}
            alt="Reference for Apolon Shop - Internet Trgovina"
            className={styles.reference_img}
            height="100%"
          />
          <p className={styles.title_link}>
            <span className={styles.number}>03</span>
            <span className={styles.title}>
              Apolon Shop - Internet Trgovina
            </span>
            <span className={styles.hashes}>
              #e-commerce
              <br />
              #shop
              <br />
              #marketing
            </span>
          </p>
        </a>
        <a
          href="http://apartmanimihaela.com/"
          target="_blank"
          rel="noreferrer"
          className={styles.list_of_references}
        >
          <img
            src={ApartmaniMihaelaReferenca}
            alt="Reference for Apartmani Mihaela"
            className={styles.reference_img}
            height="100%"
          />
          <p className={styles.title_link}>
            <span className={styles.number}>02</span>
            <span className={styles.title}>Apartmani Mihaela</span>
            <span className={styles.hashes}>
              #landing
              <br />
              #booking
              <br />
              #logo
            </span>
          </p>
        </a>
        <a
          href="https://top-tim.com/"
          target="_blank"
          rel="noreferrer"
          className={styles.list_of_references}
        >
          <img
            src={TopTimReferenca}
            alt="Reference for Top Tim - Internet Trgovina"
            className={styles.reference_img}
            height="100%"
          />
          <p className={styles.title_link}>
            <span className={styles.number}>01</span>
            <span className={styles.title}>Top Tim - Internet Trgovina</span>
            <span className={styles.hashes}>
              #e-commerce
              <br />
              #shop
              <br />
              #marketing
            </span>
          </p>
        </a>
      </div>
    </LandscapeTablet>
  </>
);

export default HorizontalScrollReferences;
