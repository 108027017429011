import React, { useState } from "react";
import Particles from "react-tsparticles";
import particlesConfig from "../../config/particlesConfig";
import MenuButton from "../../components/home-page/header/MenuButton";
import Menu from "../../components/home-page/header/Menu";
import Hero from "../../components/home-page/hero/Hero";
import HorizontalComponent from "../../components/helpers/HorizontalComponent";
import Section1 from "../../components/home-page/section1/Section1";
import ReferencesMain from "../../components/home-page/references/ReferencesMain";
import HorizontalScrollReferences from "../../components/home-page/references/HorizontalScrollReferences";
import ContactUs from "../../components/home-page/contact_us/ContactUs";

const HomePageSmallerDesktop = function (props) {
  const [isMenuOpen, setMenu] = useState(false);

  const handleMenu = () => {
    setMenu((prevState) => !prevState);
  };

  return (
    <React.Fragment>
      <div style={{ position: "fixed" }}>
        <Particles height="100vh" width="100vw" params={particlesConfig} />
      </div>
      <header className="header">
        <MenuButton onClick={handleMenu}></MenuButton>
        <Menu
          isMenuOpen={isMenuOpen}
          onClick={handleMenu}
          setMenu={setMenu}
        ></Menu>
      </header>
      <main className="animation_on_load main">
        <section className="section_hero">
          <Hero></Hero>
          <HorizontalComponent></HorizontalComponent>
        </section>
        <section className="section_section1">
          <Section1></Section1>
        </section>
        <section className="section_references">
          <ReferencesMain></ReferencesMain>
          <HorizontalScrollReferences></HorizontalScrollReferences>
        </section>
      </main>
      <footer>
        <section className="section_contact">
          <ContactUs></ContactUs>
        </section>
      </footer>
    </React.Fragment>
  );
};

export default HomePageSmallerDesktop;
