import React, { useState } from "react";
import styles from "./Post.module.css";
import { Link } from "react-router-dom";
import Particles from "react-tsparticles";
import particlesConfig from "../../config/particlesConfig";
import MenuButton from "../home-page/header/MenuButton";
import Menu from "../home-page/header/Menu";
import GetAnEstimate from "../helpers/GetAnEstimate";
import logo from "../../assets/logo192.png";
import SinglePost from "./SinglePost";

import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

function Post() {
  const [isMenuOpen, setMenu] = useState(false);

  const handleMenu = () => {
    setMenu((prevState) => !prevState);
  };

  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <div style={{ position: "absolute" }}>
          <Particles height="100vh" width="100vw" params={particlesConfig} />
        </div>
        <header className="header">
          <MenuButton onClick={handleMenu}></MenuButton>
          <Menu
            isMenuOpen={isMenuOpen}
            onClick={handleMenu}
            setMenu={setMenu}
          ></Menu>
          <Link to="/">
            <img
              src={logo}
              alt="Redsync Logo Without Moto"
              className="logo_homepage"
            />
          </Link>
          <GetAnEstimate></GetAnEstimate>
          <Link to="/blog" className={styles.link_back}>
            Back To All Posts
          </Link>
        </header>
        <main className={styles.main_wrapper}>
          <SinglePost></SinglePost>
        </main>
      </QueryClientProvider>
    </React.Fragment>
  );
}

export default Post;
