import React, { useRef, useState, Suspense } from "react";
import styles from "./Description.module.css";
import { Canvas, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { PresentationControls } from "@react-three/drei";
import kocka from "../../../../assets/kub.glb";
import { useMediaQuery } from "react-responsive";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1344 });
  return isDesktop ? children : null;
};

const LandscapeTablet = ({ children }) => {
  const isLandscapeTablet = useMediaQuery({ minWidth: 944, maxWidth: 1343 });
  return isLandscapeTablet ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 704, maxWidth: 943 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isTablet = useMediaQuery({ maxWidth: 703 });
  return isTablet ? children : null;
};

const Model = () => {
  const gltf = useLoader(GLTFLoader, kocka);
  return (
    <>
      <primitive object={gltf.scene} scale={2.8} />
    </>
  );
};

const Description = function (props) {
  const [hold, setHold] = useState(false);

  return (
    <React.Fragment>
      <Desktop>
        <Canvas camera={{ position: [5, 5, 5] }} className={styles.canvas}>
          <Suspense fallback={2}>
            <PresentationControls polar={[-Infinity, Infinity]}>
              <Model />
              <ambientLight intensity={1} />
            </PresentationControls>
          </Suspense>
        </Canvas>
      </Desktop>
      <LandscapeTablet>
        <Canvas camera={{ position: [6, 5, 5] }} className={styles.canvas}>
          <Suspense fallback={2}>
            <PresentationControls polar={[-Infinity, Infinity]}>
              <Model />
              <ambientLight intensity={1} />
            </PresentationControls>
          </Suspense>
        </Canvas>
      </LandscapeTablet>
      <Tablet>
        <Canvas camera={{ position: [8, 5, 5] }} className={styles.canvas}>
          <Suspense fallback={2}>
            <PresentationControls polar={[-Infinity, Infinity]}>
              <Model />
              <ambientLight intensity={1} />
            </PresentationControls>
          </Suspense>
        </Canvas>
      </Tablet>
      <Mobile>
        <Canvas camera={{ position: [10, 5, 5] }} className={styles.canvas}>
          <Suspense fallback={2}>
            <PresentationControls polar={[-Infinity, Infinity]}>
              <Model />
              <ambientLight intensity={1} />
            </PresentationControls>
          </Suspense>
        </Canvas>
      </Mobile>
    </React.Fragment>
  );
};

export default Description;
