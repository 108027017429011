import React from "react";
import Card from "./Card";
import { Link } from "react-router-dom";

const DesignCard = function (props) {
  return (
    <React.Fragment>
      <Link to="/graphic-design" style={{ textDecoration: "none" }}>
        <Card>
          <span>03</span>
          <h2>
            Graphic
            <br />
            <strong>Design</strong>
            <br />
            Services
          </h2>
          <h3>
            #logo
            <br />
            #brandbook
            <br />
            #packing
          </h3>
        </Card>
      </Link>
    </React.Fragment>
  );
};
export default DesignCard;
