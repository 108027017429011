import React from "react";
import styles from "./MenuButton.module.css";

const MenuButton = function (props) {
  return (
    <React.Fragment>
      <div className={styles.menu_button} onClick={props.onClick}>
        <svg
          className={styles.menu_icon}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h8m-8 6h16"
          />
        </svg>
        <span>menu</span>
      </div>
    </React.Fragment>
  );
};

export default MenuButton;
