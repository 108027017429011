import React from "react";
import styles from "./SinglePost.module.css";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { useQuery } from "react-query";
import { useParams } from "react-router";

import { gql, request } from "graphql-request";
import SinglePostContent from "./SinglePostContent";

const endpoint1 = process.env.REACT_APP_ENDPOINT_BLOG;

const POST = gql`
  query GetPost($slug: String!) {
    post(where: { slug: $slug }) {
      title
      slug
      id
      createdAt
      excerpt
      content {
        raw
      }
      author {
        name
      }
      featuredImage {
        url
      }
      category {
        name
      }
    }
  }
`;

function SinglePost() {
  const { slug } = useParams();
  const { data, isLoading, error } = useQuery(`post-${slug}`, () => {
    return request(endpoint1, POST, { slug });
  });

  if (isLoading) return <span className={styles.loading}>Loading...</span>;
  if (error)
    return <pre className={styles.error_message}>Error: No Posts Found</pre>;

  if (data.post === null) {
    return <pre className={styles.error_message}>Error: Post not found</pre>;
  }

  return (
    <HelmetProvider>
      <Helmet>
        <meta
          property="og:title"
          content={`${data.post.title} | REDsync - Digital Agency`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={data.post.featuredImage.url} />
        <meta property="og:description" content={data.post.excerpt} />
        <meta property="og:image:alt" content={data.post.excerpt} />

        <meta property="og:site_name" content="REDsync - Digital Agency" />
      </Helmet>

      <article className={styles.wrapper}>
        <h1 className={styles.post_heading}>{data.post.title}</h1>
        <aside className={styles.info_about_post}>
          <p className={`${styles.author} ${styles.display_flex_column}`}>
            <span>Author</span>
            <span>{data.post.author.name}</span>
          </p>
          <p className={`${styles.category} ${styles.display_flex_column}`}>
            <span>Category</span>
            <span>{data.post.category.name}</span>
          </p>
          <p className={`${styles.date} ${styles.display_flex_column}`}>
            <span>Date</span>
            <span>
              {new Date(data.post.createdAt).toLocaleDateString("en-US", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </span>
          </p>
        </aside>
        <img
          src={data.post.featuredImage.url}
          alt={data.post.title}
          className={styles.single_post_image}
        />
        <div className={styles.single_post_excerpt_div}>
          <h2 className={styles.single_post_excerpt_title}>About This Post</h2>
          <p className={styles.single_post_excerpt_content}>
            {data.post.excerpt}
          </p>
        </div>
        <div className={styles.content}>
          <div className={styles.post_content}>
            <SinglePostContent post={data.post} />
          </div>
        </div>
      </article>
    </HelmetProvider>
  );
}

export default SinglePost;
