import React from "react";
import styles from "./Card.module.css";
import Tilt from "react-parallax-tilt";

const Card = function (props) {
  return (
    <React.Fragment>
      <Tilt
        className={styles.card}
        style={{ background: "" }}
        scale={1.05}
        tiltMaxAngleX={5}
        tiltMaxAngleY={5}
      >
        {props.children}
      </Tilt>
    </React.Fragment>
  );
};

export default Card;
