import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import Tilt from "react-parallax-tilt";
import { ReactComponent as FacebookLogo } from "../../../assets/facebook_icon.svg";
import { ReactComponent as InstagramLogo } from "../../../assets/instagram_icon.svg";
import { ReactComponent as LinkedinLogo } from "../../../assets/linkedin_icon.svg";
import { ReactComponent as TwitterLogo } from "../../../assets/twitter_icon.svg";
import GetAnEstimate from "../../helpers/GetAnEstimate";
import ModalLegal from "../../helpers/ModalLegal";
import ModalPrivacy from "../../helpers/ModalPrivacy";
import ContactForm from "./ContactForm";
import styles from "./ContactUs.module.css";

const ContactUs = function (props) {
  const [year, setYear] = useState(new Date().getFullYear());
  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);

  const [modalPrivacy, setModalPrivacy] = useState();

  const onClickHandler = function (e) {
    e.preventDefault();
    setModalPrivacy(true);
    document.body.style.overflow = "hidden";
  };

  const resetModalPrivacy = function (e) {
    e.preventDefault();
    setModalPrivacy(null);
    document.body.style.overflow = "unset";
  };
  const [modalLegal, setModalLegal] = useState();

  const onClickHandlerLegal = function (e) {
    e.preventDefault();
    setModalLegal(true);
    document.body.style.overflow = "hidden";
  };

  const resetModalLegal = function (e) {
    e.preventDefault();
    setModalLegal(null);
    document.body.style.overflow = "unset";
  };

  const { ref, inView } = useInView({ threshold: 0.2 });

  return (
    <React.Fragment>
      {inView ? <></> : <GetAnEstimate></GetAnEstimate>}
      <div className={styles.contact_us_main} ref={ref}>
        <Tilt scale={1.1} tiltMaxAngleX={10} tiltMaxAngleY={5}>
          <h2 className={styles.contact_us_title}>
            Let's Create Something <br />
            Great Together
          </h2>
        </Tilt>
        <div className={styles.contact_footer_main}>
          <h3 className={styles.contact_h3}>
            Have An Idea? <br /> Let's Build It!
          </h3>
          <div className={styles.contact_us_content}>
            <div>
              <a
                href="mailto: create@redsync.studio"
                className={styles.email_address}
              >
                create@redsync.studio
              </a>
            </div>
            <div>
              <a href="tel:+385916126544" className={styles.phone}>
                +385 91 6126 544
              </a>
            </div>
            <div className={styles.socialmedia}>
              <a
                href="https://www.facebook.com/Redsync-Digital-Agency-105179612163683"
                className={styles.logo}
                target="_blank"
                rel="noreferrer"
              >
                <FacebookLogo></FacebookLogo>
              </a>
              <a
                href="https://www.instagram.com/redsyncdigital/"
                className={styles.logo}
                target="_blank"
                rel="noreferrer"
              >
                <InstagramLogo></InstagramLogo>
              </a>
              <a
                href="https://www.linkedin.com/company/redsync-digital-agency/"
                className={styles.logo}
                target="_blank"
                rel="noreferrer"
              >
                <LinkedinLogo></LinkedinLogo>
              </a>
              <a
                href="https://twitter.com/redsync_digital"
                className={styles.logo}
                target="_blank"
                rel="noreferrer"
              >
                <TwitterLogo></TwitterLogo>
              </a>
            </div>
          </div>
          <div className={styles.legal}>
            <p className={styles.copyright}>
              &copy; Redsync. All Rights Reserved {year}
            </p>
            <button onClick={onClickHandler} className={styles.button_footer}>
              Privacy Policy
            </button>
            {modalPrivacy && (
              <ModalPrivacy onClick={resetModalPrivacy}></ModalPrivacy>
            )}
            <button
              onClick={onClickHandlerLegal}
              className={styles.button_footer}
            >
              Legal Information
            </button>
            {modalLegal && <ModalLegal onClick={resetModalLegal}></ModalLegal>}
          </div>
          <div className={styles.contact_form}>
            <ContactForm></ContactForm>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactUs;
