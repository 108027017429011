import emailjs from "@emailjs/browser";
import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Tilt from "react-parallax-tilt";
import ContactModal from "../../helpers/ContactModal";
import styles from "./ContactForm.module.css";

const ContactForm = () => {
  const [agreeToNewsletter, setAgreeToNewsletter] = useState(true);
  const [status, setStatus] = useState("Submit");
  const [showMessage, setShowMessage] = useState(false);
  const [modalContact, setModalContact] = useState();
  const [dataResponse, setDataResponse] = useState();
  const formRef = useRef();
  const recaptchaRef = useRef();
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setStatus("Sending...");
  //   const {
  //     name,
  //     email,
  //     company,
  //     phone,
  //     message,
  //     webDev,
  //     digMark,
  //     graphDes,
  //     bussAn,
  //     newsletter,
  //   } = e.target.elements;
  //   let details = {
  //     name: name.value,
  //     email: email.value,
  //     company: company.value,
  //     phone: phone.value,
  //     message: message.value,
  //     services: {
  //       development: webDev.checked,
  //       marketing: digMark.checked,
  //       design: graphDes.checked,
  //       business: bussAn.checked,
  //     },
  //     newsletter: newsletter.checked,
  //   };
  //   let response = await fetch("http://localhost:3001", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json;charset=utf-8",
  //     },
  //     body: JSON.stringify(details),
  //   });
  //   setStatus("Submit");
  //   let result = await response.json();
  //   if (result.status) formRef.current.reset();
  //   setShowMessage(result.status);
  //   setModalContact(true);
  //   document.body.style.overflow = "hidden";
  //   setDataResponse(result.message);
  // };

  const sendEmail = (e) => {
    setStatus("Sending...");
    e.preventDefault();
    recaptchaRef.current.execute();
    emailjs
      .sendForm(
        process.env.REACT_APP_FORM_SERVICE_ID,
        process.env.REACT_APP_FORM_TEMPLATE_ID,
        formRef.current,
        process.env.REACT_APP_FORM_USER_ID
      )
      .then(
        (result) => {
          if (result.status === 200) formRef.current.reset();
          setShowMessage("Message Sent Successfully!");
          setModalContact(true);
          document.body.style.overflow = "hidden";
          setDataResponse("Message Sent Successfully!");
          setStatus("Submit");
        },
        (error) => {
          console.log(error);
          setShowMessage("ERROR - Please Try Again!");
          setModalContact(true);
          document.body.style.overflow = "hidden";
          setDataResponse("ERROR - Please Try Again!");
          setStatus("Submit");
        }
      );
  };

  const clearForm = function (e) {
    setStatus("Submit");
  };

  const resetModalContact = function (e) {
    e.preventDefault();
    setModalContact(null);
    document.body.style.overflow = "unset";
  };

  return (
    <React.Fragment>
      <form
        id="contact_form"
        onSubmit={sendEmail}
        className={styles.form}
        ref={formRef}
      >
        <ReCAPTCHA
          className={styles.captcha}
          ref={recaptchaRef}
          size="invisible"
          sitekey="6Ldbz1gfAAAAAP7UAxFeqPlleIXLxMoTUsz6AORp"
        />
        {showMessage && modalContact && (
          <ContactModal
            onClick={resetModalContact}
            passData={dataResponse}
          ></ContactModal>
        )}
        <div className={styles.basic_info}>
          <div className={styles.header}>
            <div className={styles.contact_name}>
              <input
                name="name"
                className={styles.input_name}
                type="text"
                placeholder="Your Name*"
                required
              ></input>
              <label htmlFor="name" className={styles.label_name}>
                Your Name*
              </label>
            </div>
            <div className={styles.contact_name}>
              <input
                className={styles.input_name}
                type="email"
                placeholder="Email Adress*"
                required
                name="email"
              ></input>
              <label htmlFor="email" className={styles.label_name}>
                Your Email*
              </label>
            </div>
          </div>
          <div className={styles.header}>
            <div className={styles.contact_name}>
              <input
                className={styles.input_name}
                type="text"
                placeholder="Company Name*"
                name="company"
              ></input>
              <label htmlFor="company" className={styles.label_name}>
                Company Name
              </label>
            </div>
            <div className={styles.contact_name}>
              <input
                className={styles.input_name}
                type="tel"
                placeholder="Phone"
                name="phone"
              ></input>
              <label htmlFor="phone" className={styles.label_name}>
                Phone Number
              </label>
            </div>
          </div>
        </div>
        <div className={styles.contact_checkbox}>
          <span>You Need Us For: *</span>

          <div className={styles.checkbox_item}>
            <input
              name="webDev"
              className={styles.checkbox_input}
              type="checkbox"
            ></input>
            <label htmlFor="webDev" className={styles.label_check}>
              Website
              <br />
              Development
            </label>
          </div>
          <div className={styles.checkbox_item}>
            <input
              name="digMark"
              className={styles.checkbox_input}
              type="checkbox"
            ></input>
            <label htmlFor="digMark" className={styles.label_check}>
              Digital
              <br />
              Marketing
            </label>
          </div>

          <div className={styles.checkbox_item}>
            <input
              name="graphDes"
              className={styles.checkbox_input}
              type="checkbox"
            ></input>
            <label htmlFor="graphDes" className={styles.label_check}>
              Graphic
              <br />
              Design
            </label>
          </div>
          <div className={styles.checkbox_item}>
            <input
              name="bussAn"
              className={styles.checkbox_input}
              type="checkbox"
            ></input>
            <label htmlFor="bussAn" className={styles.label_check}>
              Business
              <br />
              Consulting
            </label>
          </div>
        </div>
        <div className={styles.contact_message}>
          <textarea
            className={styles.input_message}
            placeholder="Message"
            required
            name="message"
          ></textarea>
          <label htmlFor="message" className={styles.label_message}>
            Tell Us About Your Project*
          </label>
        </div>
        <div className={styles.newsletter}>
          <input
            type="checkbox"
            name="newsletter"
            className={styles.newsletter_input}
            checked={agreeToNewsletter}
            onChange={() => setAgreeToNewsletter(!agreeToNewsletter)}
          ></input>
          <label htmlFor="newsletter" className={styles.newsletter_label}>
            I agree to receive occasional Redsync newsletters containing news
            and advice on creating personal and business progress via digital
            tech.
          </label>
        </div>
        <div className={styles.buttons}>
          <Tilt scale={1.05} tiltMaxAngleX={0} tiltMaxAngleY={0}>
            <button type="submit" className={styles.submit_button}>
              {status}
            </button>
          </Tilt>

          <Tilt scale={1.05} tiltMaxAngleX={0} tiltMaxAngleY={0}>
            <button
              type="reset"
              className={styles.clear_button}
              onClick={clearForm}
            >
              Clear Form
            </button>
          </Tilt>
        </div>
      </form>
    </React.Fragment>
  );
};

export default ContactForm;
