import React from "react";
import styles from "./BlogHome.module.css";
import Tilt from "react-parallax-tilt";

import Posts from "./Posts";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

function BlogHome() {
  return (
    <QueryClientProvider client={queryClient}>
      <main className={styles.container}>
        <h1 className={styles.heading}>
          <Tilt scale={1.05} tiltMaxAngleX={5} tiltMaxAngleY={5}>
            Thing We Learned
            <br />
            And Thing
            <br />
            You Can Learn
          </Tilt>
        </h1>
        <div className={styles.all_posts}>
          <Posts></Posts>
        </div>
      </main>
    </QueryClientProvider>
  );
}

export default BlogHome;
