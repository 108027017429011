import React from "react";
import styles from "./ContactModal.module.css";

function ContactModal(props) {
  return (
    <React.Fragment>
      <div className={styles.backdrop} onClick={props.onClick}></div>

      <div className={styles.open_modal}>
        <button onClick={props.onClick} className={styles.modal_button}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={styles.close}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <article className={styles.modal_content}>
          <h1 className={styles.modal_h1}>{props.passData}</h1>
        </article>
      </div>
    </React.Fragment>
  );
}

export default ContactModal;
