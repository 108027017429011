import React from "react";
import styles from "./ReferencesTablet.module.css";
import TopTimReferenca from "../../../assets/reference/TopTimReferenca.png";
import ApolonShopReferenca from "../../../assets/reference/ApolonShopReferenca.png";
import ApartmaniMihaelaReferenca from "../../../assets/reference/ApartmaniMihaelaReferenca.png";

function ReferencesTablet() {
  return (
    <React.Fragment>
      <div className={styles.reference_container}>
        <a
          href="https://top-tim.com/"
          target="_blank"
          rel="noreferrer"
          className={styles.list_of_references}
        >
          <img
            src={TopTimReferenca}
            alt="Reference for Top Tim - Internet Trgovina"
            className={styles.reference_img}
            height="100%"
          />
          <p className={styles.title_link}>
            <span className={styles.number}>01</span>
            <span className={styles.title}>Top Tim - Internet Trgovina</span>
            <span className={styles.hashes}>
              #e-commerce
              <br />
              #shop
              <br />
              #marketing
            </span>
          </p>
        </a>
        <a
          href="http://apartmanimihaela.com/"
          target="_blank"
          rel="noreferrer"
          className={styles.list_of_references}
        >
          <img
            src={ApartmaniMihaelaReferenca}
            alt="Reference for Apartmani Mihaela"
            className={styles.reference_img}
            height="100%"
          />
          <p className={styles.title_link}>
            <span className={styles.number}>02</span>
            <span className={styles.title}>Apartmani Mihaela</span>
            <span className={styles.hashes}>
              #landing
              <br />
              #booking
              <br />
              #logo
            </span>
          </p>
        </a>

        <a
          href="https://apolonshop.com/"
          target="_blank"
          rel="noreferrer"
          className={styles.list_of_references}
        >
          <img
            src={ApolonShopReferenca}
            alt="Reference for Apolon Shop - Internet Trgovina"
            className={styles.reference_img}
            height="100%"
          />
          <p className={styles.title_link}>
            <span className={styles.number}>03</span>
            <span className={styles.title}>
              Apolon Shop - Internet Trgovina
            </span>
            <span className={styles.hashes}>
              #e-commerce
              <br />
              #shop
              <br />
              #marketing
            </span>
          </p>
        </a>
      </div>
    </React.Fragment>
  );
}

export default ReferencesTablet;
