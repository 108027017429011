import React from "react";
import styles from "./Hero.module.css";
import Logo from "../header/Logo";
import ScrollDown from "../../helpers/ScrollDown";
import Tilt from "react-parallax-tilt";

const Hero = function (props) {
  return (
    <React.Fragment>
      <div className={`${styles.hero_main_grid_style}`}>
        <div className={`${styles.stick_on_scroll} ${styles.display_flex}`}>
          <Tilt tiltMaxAngleX={0} tiltMaxAngleY={10}>
            <Logo></Logo>
          </Tilt>
          <ScrollDown></ScrollDown>
          <h1 className={`${styles.hero_section_h1}`}>
            <Tilt scale={1.05} tiltMaxAngleX={5} tiltMaxAngleY={5}>
              <span className={styles.animation_h1}>W</span>
              <span className={styles.animation_h1}>e</span>
              <span className={styles.animation_h1}> </span>
              <span className={styles.animation_h1}>M</span>
              <span className={styles.animation_h1}>a</span>
              <span className={styles.animation_h1}>k</span>
              <span className={styles.animation_h1}>e</span>
              <br />
              <span className={styles.animation_h1}>W</span>
              <span className={styles.animation_h1}>h</span>
              <span className={styles.animation_h1}>a</span>
              <span className={styles.animation_h1}>t</span>
              <span className={styles.animation_h1}> </span>
              <span className={styles.animation_h1}>I</span>
              <span className={styles.animation_h1}>s</span>
              <span className={styles.animation_h1}> </span>
              <span className={styles.animation_h1}>Y</span>
              <span className={styles.animation_h1}>e</span>
              <span className={styles.animation_h1}>t</span>
              <br />
              <span className={styles.animation_h1}>T</span>
              <span className={styles.animation_h1}>o</span>
              <span className={styles.animation_h1}> </span>
              <span className={styles.animation_h1}>C</span>
              <span className={styles.animation_h1}>o</span>
              <span className={styles.animation_h1}>m</span>
              <span className={styles.animation_h1}>e</span>
            </Tilt>
          </h1>
          <Tilt scale={1.1} tiltMaxAngleX={10} tiltMaxAngleY={10}>
            <p className={`${styles.hero_section_p} `}>
              Sit back and enjoy your coffee,
              <br />
              while we translate your hopes and
              <br />
              plans into digital reality
              <br />
            </p>
          </Tilt>
          {/* <Link
            to="/contact-us"
            style={{ textDecoration: "none", paddingTop: "2rem" }}
          >
            <Tilt scale={1.1} tiltMaxAngleX={0} tiltMaxAngleY={20}>
              <div id="cards" className={`${styles.hero_section_a} `}>
                <span>Send Request</span>
              </div>
            </Tilt>
          </Link> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Hero;
