import React from "react";
import styles from "./CarouselHero.module.css";
import WebsiteCard from "./cards/WebsiteCard";
import MarketingCard from "./cards/MarketingCard";
import DesignCard from "./cards/DesignCard";
import BussinesCard from "./cards/BussinesCard";

function CarouselHero() {
  return (
    <React.Fragment>
      <div className={styles.carousel_cards}>
        <WebsiteCard></WebsiteCard>
        <MarketingCard></MarketingCard>
        <DesignCard></DesignCard>
        <BussinesCard></BussinesCard>
      </div>
    </React.Fragment>
  );
}

export default CarouselHero;
