/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import HorizontalScrollReferenceTitle from "./HorizontalScrollReferenceTitle";

const TitleContainer = styled.div`
  position: relative;
  height: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 24rem;
  padding: 10rem 0 0 0;
`;

const TallOuterContainer = styled.div.attrs(({ dynamicHeight }) => ({
  style: { height: `${dynamicHeight}px` },
}))`
  position: relative;
  width: 100%;
`;

const StickyInnerContainer = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: hidden;
`;

const HorizontalTranslateContainer = styled.div.attrs(({ translateX }) => ({
  style: { transform: `translateX(${translateX}px)` },
}))`
  position: absolute;
  will-change: transform;
  padding-left: 8rem;
`;

const HorizontalTranslateContainer1 = styled.div.attrs(
  ({ translateY, translateX }) => ({
    style: {
      transform: `translateY(${translateY}px) translateX(${translateX}px)`,
    },
  })
)`
  position: absolute;
  will-change: transform;
`;

const calcDynamicHeight = (objectWidth) => {
  const vw = window.innerWidth;
  const vh = window.innerHeight;
  if (vw > 1343 && vw < 1699) return (objectWidth = 2900);
  else return objectWidth - vw + vh + 1100;
};

const handleDynamicHeight = (ref, setDynamicHeight) => {
  if (ref.current === null) return;
  const objectWidth = ref.current.scrollWidth;
  const dynamicHeight = calcDynamicHeight(objectWidth);
  setDynamicHeight(dynamicHeight);
};

const applyScrollListener = (ref, setTranslateX) => {
  window.addEventListener("scroll", (e) => {
    if (ref.current === null) return;
    const offsetTop = -ref.current.offsetTop + 2060;
    setTranslateX(offsetTop);
  });
};

const applyScrollListener1 = (ref, setTranslateY, setTranslateX1) => {
  window.addEventListener("scroll", (e) => {
    if (ref.current === null) return;
    const offsetTop = (-ref.current.offsetTop - 100) * 0.2;
    setTranslateY(offsetTop);
    const offsetTopX = -ref.current.offsetTop + 2060;
    setTranslateX1(offsetTopX);
  });
};

export default ({ children }) => {
  const [dynamicHeight, setDynamicHeight] = useState(null);
  const [translateX, setTranslateX] = useState(0);
  const [translateX1, setTranslateX1] = useState(0);
  const [translateY, setTranslateY] = useState(0);

  const containerRef = useRef(null);
  const objectRef = useRef(null);

  const resizeHandler = () => {
    handleDynamicHeight(objectRef, setDynamicHeight);
  };

  useEffect(() => {
    handleDynamicHeight(objectRef, setDynamicHeight);
    window.addEventListener("resize", resizeHandler);
    applyScrollListener(containerRef, setTranslateX);
    applyScrollListener1(containerRef, setTranslateY, setTranslateX1);
  }, []);

  return (
    <TallOuterContainer dynamicHeight={dynamicHeight}>
      <StickyInnerContainer ref={containerRef}>
        <HorizontalTranslateContainer1
          translateX={-translateX1}
          translateY={-translateY}
          ref={objectRef}
        >
          <TitleContainer>
            <HorizontalScrollReferenceTitle></HorizontalScrollReferenceTitle>
          </TitleContainer>
        </HorizontalTranslateContainer1>
        <HorizontalTranslateContainer translateX={-translateX} ref={objectRef}>
          {children}
        </HorizontalTranslateContainer>
      </StickyInnerContainer>
    </TallOuterContainer>
  );
};
