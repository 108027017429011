import React from "react";
import styled from "styled-components";
import BussinesCard from "../home-page/hero/cards/BussinesCard";
import DesignCard from "../home-page/hero/cards/DesignCard";
import MarketingCard from "../home-page/hero/cards/MarketingCard";
import WebsiteCard from "../home-page/hero/cards/WebsiteCard";

import "animate.css/animate.min.css";
import HorizontalScroll from "./horizontal-scroll";

const Main = styled.main`
  padding-left: 10rem;
  grid-column: 2;
  @media (max-width: 1699px) {
    padding-left: 0;
  }
`;

const HorizontalSection = styled.section`
  position: relative;
  width: 100%;
`;

const CardsContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10rem;
  padding: 0 90rem 0 20rem;
  @media (max-width: 1699px) {
    padding: 0 70rem 0 20rem;
  }
`;

const HorizontalComponent = () => (
  <Main>
    <HorizontalSection>
      <HorizontalScroll>
        <CardsContainer>
          <WebsiteCard></WebsiteCard>
          <MarketingCard></MarketingCard>
          <DesignCard></DesignCard>
          <BussinesCard></BussinesCard>
        </CardsContainer>
      </HorizontalScroll>
    </HorizontalSection>
  </Main>
);

export default HorizontalComponent;
