import React from "react";
import styles from "./Logo.module.css";
import logo from "../../../assets/logo_natpis.png";

const Logo = function (props) {
  return (
    <React.Fragment>
      <div className={styles.logo_wrapper}>
        <a href="/" className={styles.logo_link}>
          <img
            className={styles.logo_img}
            src={logo}
            alt="Redsync Digital Agency Logo"
            width="20rem"
            height="100%"
          />
        </a>
      </div>
    </React.Fragment>
  );
};

export default Logo;
