import React from "react";
import { Link } from "react-router-dom";
import Card from "./Card";

const WebsiteCard = function (props) {
  return (
    <React.Fragment>
      <Link to="/website-development" style={{ textDecoration: "none" }}>
        <Card>
          <span>01</span>
          <h2>
            Dynamic
            <br />
            <strong>Website</strong>
            <br />
            Development
          </h2>
          <h3>
            #ui/ux
            <br />
            #landing
            <br />
            #e-shop
          </h3>
        </Card>
      </Link>
    </React.Fragment>
  );
};
export default WebsiteCard;
