import React from "react";
import { Link } from "react-router-dom";
import styles from "./GetAnEstimate.module.css";

const GetAnEstimate = function (props) {
  return (
    <React.Fragment>
      <div>
        <Link to="/contact-us">
          <div id="cards" className={`${styles.get_link}`}>
            <span className={styles.link_span}>GET AN ESTIMATE</span>
          </div>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default GetAnEstimate;
