import React from "react";
import styles from "./ModalPrivacy.module.css";

function ModalPrivacy(props) {
  return (
    <React.Fragment>
      <div className={styles.backdrop} onClick={props.onClick}></div>

      <div className={styles.open_modal}>
        <button onClick={props.onClick} className={styles.modal_button}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={styles.close}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <article className={styles.modal_content}>
          <h1 className={styles.modal_h1}>Privacy Policy</h1>
          <h2 className={styles.modal_h2}>1. Introduction</h2>
          <p className={styles.modal_p}>
            The company Redsync j.d.o.o., Mače, Veliki Komor 14B, Personal
            identification number: 43329656252 (hereinafter referred to as:
            Redsync) takes the question of security and protection of personal
            information very seriously. Taking the mentioned into consideration,
            Redsync takes special care that users of the website
            www.redsync.studio (hereinafter referred to as: users) are entirely
            informed about rights they might have regarding the collection of
            their personal information, as well as regarding security measures
            taken by Redsync. By accessing and/or using the website
            www.redsync.studio, the user agrees to have read, understood and
            accepted the terms from this Non-disclosure statement. If the user
            does not agree, they should refrain from using the website, leave,
            and not access and/or use the website www.redsync.studio, as well as
            further on not access and/or use the website again.{" "}
          </p>
          <h2 className={styles.modal_h2}>
            2. Amendment to the Privacy Policy
          </h2>
          <p className={styles.modal_p}>
            Redsync is authorized to amend the Non-disclosure statement at any
            time by publishing the amended text of the statement on the website
            www.redsync.studio. The amendments shall become effective
            immediately after their publication on the website. Any use of the
            website by the end user after such publication shall be considered
            as acceptance of those terms. The user shall periodically re-read
            the Non-disclosure statement to keep informed of any changes. If the
            user uses the website after the Non-disclosure statement has been
            amended, it shall be presumed that they have learned, understood and
            fully accepted the amendments. Liability of Redsync for any damage
            caused to users or third parties due to such amendments shall be
            excluded.
          </p>
          <h2 className={styles.modal_h2}>
            3. Type of Data Collected and Use of the Data Collected
          </h2>
          <p className={styles.modal_p}>
            Redsync collects personal information that identifies users of the
            website www.redsync.studio. When a user joins in on certain
            activities on the website www.redsync.studio, such as newsletter
            subscription, participating in competitions organized by Redsync,
            filling out surveys, posting comments, sending feedback to Redsync,
            seeking information about Redsync products and services, applying
            for jobs, Redsync has the right to demand the users provide certain
            information about themselves. The user shall not be obliged to join
            in on the mentioned activities. If the user chooses to join in,
            Redsync shall be authorized to request the user's personal
            information, which, depending on the activity, can include: name and
            surname, address (including ZIP code), e-mail address, telephone
            number including cellphone number, date of birth, place of work and
            employer information. Redsync shall be authorized to use the
            personal information of the user to offer products and/or services,
            improve the quality of products and/or services, organize
            competitions, improve the functioning of the website, enhance the
            advertising and promotional activities, analyse the use of the
            website and inquire about the user's experience with third parties.
            Redsync shall be authorized to collect information that does not
            identify a specific end user, including the Uniform Resource Locator
            (URL) of the website the user visited prior to opening the website
            of www.redsync.studio, the URL of the website that the user visited
            after leaving the website of www.redsync.studio, the type of browser
            the user is using and the user's Internet Protocol (IP) address.
            Authorized service providers and advertisers may automatically
            collect this information when visiting the Redsync website either by
            means of „cookies” or other tools. Redsync shall use such
            information solely to resolve problems, administer the website,
            analyse trends, collect demographic data, analyse the compliance
            with the relevant legislation and to cooperate with law enforcement
            agencies. Depending on the type of activity, some information shall
            be mandatory and some shall be optional. If the user does not
            provide the mandatory information, when such is required for an
            activity, they shall not be allowed to join in on such activity.
            Redsync shall not collect the following personal information through
            its website: name and surname, address, telephone number or online
            address of the user, unless the user voluntarily submits it,
            registers with Redsync or submits the same through a survey, through
            consent and/or if this is permitted by the relevant laws and
            regulations regarding personal data protection.
          </p>
          <h3 className={styles.modal_h3}>
            3.1. Types of candidate data we collect during recruitment process
          </h3>
          <p className={styles.modal_p}>
            Redsync processes all the candidate data you share with us for
            legitimate human resources purposes during the application or
            recruitment process. These include identifying and evaluating
            candidates for job positions at Redsync; record-keeping related to
            hiring processes; analyzing the hiring process and outcomes;
            collecting reference information and/or information received from
            background checks (where applicable), including information provided
            by third parties. In addition to using candidate data for the
            position for which you have applied, Redsync may retain and use data
            to consider you for other positions. If you do not want to be
            considered for other positions or would like to have candidate data
            removed, you may contact our data officer. Candidate data will be
            retained and used for a maximum period of 2 years in our database,
            for recruitment and hiring purposes, or a shorter period if we
            inform you differently during the hiring process.
          </p>
          <h3 className={styles.modal_h3}>
            3.2. Who may have access to candidate data
          </h3>
          <p className={styles.modal_p}>
            Within the company, candidate data will be shared with all people
            included in the recruitment process. Redsync operates in several
            countries, which means that candidate data may be stored and
            processed outside of the country or region where it was originally
            collected. We comply with relevant legislation described in our
            Privacy policy. Candidate data may be shared with our affiliates,
            subsidiaries and/or sister companies, to consider you for other
            current or future jobs and potentially include you in recruitment
            process. If you have been referred for a job at Redsync by a current
            Redsync employee, with your consent, we may inform that employee
            about the progress of your application and let the Redsync employee
            know the outcome of the process. In some cases, if it is identified
            that you have attended the same university/school or shared the same
            previous employer during the same period as a current Redsync
            employee, we may consult with that employee for feedback on you. We
            use Greenhouse and Google, as our third party data processor to
            process candidate data on our behalf, as described in their privacy
            policy.
          </p>
          <h3 className={styles.modal_h3}>
            3.3. Where we obtain candidate data
          </h3>
          <p className={styles.modal_p}>
            We collect candidate data for recruitment and hiring purposes
            automatically through our application form on this website. The data
            we collect is personal information you share with us. We also
            collect personal information from other sources. Depending on the
            relevant circumstances and applicable local laws and requirements,
            these may include personal information received in the following
            situations: your referees may disclose personal information about
            you; we may obtain personal information about you from searching for
            potential candidates from third party sources, such as LinkedIn and
            other job sites.
          </p>
          <h2 className={styles.modal_h2}>
            4. Disclosure of Personal Information
          </h2>
          <p className={styles.modal_p}>
            Redsync shall not share personal identification information with
            other parties, but it may share the information about users with its
            affiliated companies. Redsync may use third party services, such as
            Google, Mailchimp, Pipedrive, Hotjar, Greenhouse to process personal
            information on their own behalf. Such service providers are
            meticulously chosen by Redsync, especially taking into consideration
            their compliance with the relevant laws and regulations regarding
            personal data protection. Redsync shall be authorized to disclose
            information about the user only in order to protect the justified
            rights and interest of Redsync and others. Redsync shall be
            authorised to disclose the user's personal identification
            information when it is reasonable to expect that the disclosure will
            aid the enforcement of the law or when it is necessary due to a
            court order, or when its disclosure ensures compliance with this
            Non-disclosure statement and other contracts, or to protect the
            ownership rights of this website, its users or other persons. If the
            user does not wish their personal information be used in support of
            the customer relations (in particular for direct marketing or market
            research), Redsync shall respect the user's choice. Redsync shall
            not sell or market personal identification information to third
            parties.
          </p>
          <h2 className={styles.modal_h2}>
            5. User Rights regarding their Personal Identification Information
          </h2>
          <p className={styles.modal_p}>
            The user shall be authorised to change any piece of their personal
            information by sending an e-mail, in which the user shall state
            their name and surname, address and e-mail, and the information they
            would like to amend. The user shall be authorised to request their
            personal identification information be deleted or their processing
            be limited without unnecessary delay, according to the relevant laws
            and regulations regarding personal data protection. The user shall
            be authorised to receive their personal identification information,
            which they have previously provided to Redsync, and transfer the
            same to another data processor. The user shall be authorised to file
            a complaint regarding the processing of their personal
            identification information at any time, according to the relevant
            laws and regulations regarding personal data protection.
          </p>
          <h2 className={styles.modal_h2}>
            6. User Choice regarding the Collection and Use of Data
          </h2>
          <p className={styles.modal_p}>
            The user shall have the possibility to request promotional e-mail
            messages that advertise products and/or services of Redsync,
            including exclusive sales and other offers, and/or products and
            services of affiliated companies. If the user does not want to
            receive promotional e-mails, they can, at any time after having
            subscribed to the newsletter, choose the option of cancelling the
            receipt of promotions via e-mail. When contacting, the user shall
            state their name, address and e-mail address, and what type of
            promotional e-mails they no longer want to receive.
          </p>
          <h2 className={styles.modal_h2}>7. Data protection</h2>
          <p className={styles.modal_p}>
            Redsync shall undertake technical and organisational security
            measures to protect the personal data from accidental or unlawful
            destruction, loss or change, and from unauthorised disclosure or
            access. The user is familiar with the fact that no data transmission
            through the Internet or any wireless network can be 100% secure.
            Although Redsync is carrying out commercially reasonable data
            protection measures, it cannot guarantee the protection of any
            information transmitted to or from our website and shall not be
            liable for the conduct of any third party that shall receive such
            information.
          </p>
          <h2 className={styles.modal_h2}>8. Data Monitoring</h2>
          <p className={styles.modal_p}>
            Redsync shall be authorised to store „cookies” on your computer,
            which contain information about the user and are meant to save time
            for the user when using the website www.redsync.studio, and to
            monitor and direct the user's interests in order to provide services
            that are tailored to every individual user. „Cookies” shall also
            collect non-personal user information. The user may set up his
            browser to reject the „cookies”, in which case some parts of the
            Redsync website may not run correctly. Redsync may also use other
            standard technologies to monitor the use of the website and
            promotions.
          </p>
          <h2 className={styles.modal_h2}>
            9. Non-Disclosure Concerning Third Party Websites
          </h2>
          <p className={styles.modal_p}>
            This Non-disclosure statement shall only apply to the use and
            disclosure of information collected from users by Redsync. Other
            websites, which can be accessed through the Redsync website, have
            their own non disclosure statements on collecting information and on
            ways to use their websites and publish content on the same. Redsync
            shall not be liable for the methods and terms of operation of third
            parties.
          </p>
          <h2 className={styles.modal_h2}>10. Minors</h2>
          <p className={styles.modal_p}>
            Minors may only and exclusively participate in promotional
            activities of Redsync with parental consent. Redsync shall not
            intentionally collect or retain the information/personal data of
            children under the age of 16, without insisting on prior parental
            consent. Redsync shall use or publish the personal information of a
            child only and within the scope of permission given or granted by
            the parent, custodian or guardian of the child.
          </p>
          <h2 className={styles.modal_h2}>11. Effectiveness</h2>
          <p className={styles.modal_p}>
            This statement shall be effective until its revocation by Redsync.
          </p>
        </article>
      </div>
    </React.Fragment>
  );
}

export default ModalPrivacy;
