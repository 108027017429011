import React from "react";
import styles from "./HorizontalScrollReferenceTitle.module.css";

const HorizontalScrollReferenceTitle = function (props) {
  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.third_title}>
          <p className={styles.title_link}>
            <span className={styles.number}>03</span>
            <span className={styles.title}>
              Apolon Shop - Internet Trgovina
            </span>
            <span className={styles.hashes}>
              #e-commerce
              <br />
              #shop
              <br />
              #marketing
            </span>
          </p>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.second_title}>
          <p className={styles.title_link}>
            <span className={styles.number}>02</span>
            <span className={styles.title}>Apartmani Mihaela</span>
            <span className={styles.hashes}>
              #landing
              <br />
              #booking
              <br />
              #logo
            </span>
          </p>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.first_title}>
          <p className={styles.title_link}>
            <span className={styles.number}>01</span>
            <span className={styles.title}>Top Tim - Internet Trgovina</span>
            <span className={styles.hashes}>
              #e-commerce
              <br />
              #shop
              <br />
              #marketing
            </span>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HorizontalScrollReferenceTitle;
