import React from "react";
import { Link } from "react-router-dom";
import styles from "./ReferencesMain.module.css";
import Tilt from "react-parallax-tilt";
import { useMediaQuery } from "react-responsive";
import HorizontalScrollReferences from "./horizontal-scroll-references";
import ReferencesTablet from "./ReferencesTablet";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1344 });
  return isDesktop ? children : null;
};

const LandscapeTablet = ({ children }) => {
  const isLandscapeTablet = useMediaQuery({ minWidth: 944, maxWidth: 1343 });
  return isLandscapeTablet ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ maxWidth: 943 });
  return isTablet ? children : null;
};

const ReferencesMain = function (props) {
  return (
    <React.Fragment>
      <Desktop>
        <div className={`${styles.references_main_grid_style}`}>
          <div className={`${styles.stick_on_scroll} ${styles.display_flex}`}>
            <h2 className={`${styles.references_section_h1}`}>
              <Tilt scale={1.1} tiltMaxAngleX={5} tiltMaxAngleY={5}>
                Here Is Some
                <br />
                Work We
                <br />
                Are Proud Of
              </Tilt>
            </h2>
            <Tilt scale={1.1} tiltMaxAngleX={10} tiltMaxAngleY={10}>
              <p className={`${styles.references_section_p} `}>
                We are technologists that
                <br />
                understand business challenges
                <br />
                of creating digital products.
              </p>
            </Tilt>
            <Link to="/about-us" style={{ textDecoration: "none" }}>
              <Tilt scale={1.1} tiltMaxAngleX={0} tiltMaxAngleY={20}>
                <div id="cards" className={`${styles.references_section_a} `}>
                  <span>About Us</span>
                </div>
              </Tilt>
            </Link>
          </div>
        </div>
      </Desktop>
      <LandscapeTablet>
        <div className={`${styles.references_main_grid_style}`}>
          <div className={`${styles.stick_on_scroll} ${styles.display_flex}`}>
            <h2 className={`${styles.references_section_h1}`}>
              <Tilt scale={1.1} tiltMaxAngleX={5} tiltMaxAngleY={5}>
                Here Is Some
                <br />
                Work We
                <br />
                Are Proud Of
              </Tilt>
            </h2>
            <Tilt scale={1.1} tiltMaxAngleX={10} tiltMaxAngleY={10}>
              <p className={`${styles.references_section_p} `}>
                We are technologists that
                <br />
                understand business challenges
                <br />
                of creating digital products.
              </p>
            </Tilt>
            <Link to="/about-us" style={{ textDecoration: "none" }}>
              <Tilt scale={1.1} tiltMaxAngleX={0} tiltMaxAngleY={20}>
                <div id="cards" className={`${styles.references_section_a} `}>
                  <span>See More</span>
                </div>
              </Tilt>
            </Link>
          </div>
        </div>
      </LandscapeTablet>

      <Tablet>
        <div className={`${styles.references_main_grid_style}`}>
          <h2 className={`${styles.references_section_h1}`}>
            <Tilt scale={1.1} tiltMaxAngleX={5} tiltMaxAngleY={5}>
              Here Is Some
              <br />
              Work We
              <br />
              Are Proud Of
            </Tilt>
          </h2>
          <ReferencesTablet></ReferencesTablet>
          <p className={`${styles.references_section_p} `}>
            We are technologists that
            <br />
            understand business challenges
            <br />
            of creating digital products.
          </p>
          <div className={styles.link_wrapper}>
            <Link to="/about-us" style={{ textDecoration: "none" }}>
              <div className={`${styles.references_section_a} `}>
                <span>See More</span>
              </div>
            </Link>
          </div>
        </div>
      </Tablet>
    </React.Fragment>
  );
};

export default ReferencesMain;
