import React, { useState } from "react";
import Particles from "react-tsparticles";
import particlesConfig from "../config/particlesConfig";
import { Link } from "react-router-dom";
import Menu from "../components/home-page/header/Menu";
import MenuButton from "../components/home-page/header/MenuButton";
import GetAnEstimate from "../components/helpers/GetAnEstimate";
import logo from ".././assets/logo192.png";
import AboutUsHero from "../components/about-us/AboutUsHero";
import AboutUsGoals from "../components/about-us/AboutUsGoals";
import AboutUsPortfolio from "../components/about-us/AboutUsPortfolio";
import { QueryClient, QueryClientProvider } from "react-query";
import CookiesModal from "../components/helpers/CookiesModal";

const queryClient = new QueryClient();

function AboutUs() {
  const [isMenuOpen, setMenu] = useState(false);

  const handleMenu = () => {
    setMenu((prevState) => !prevState);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <CookiesModal></CookiesModal>
      <React.Fragment>
        <div style={{ position: "absolute" }}>
          <Particles height="100vh" width="100vw" params={particlesConfig} />
        </div>
        <header className="header">
          <MenuButton onClick={handleMenu}></MenuButton>
          <Menu
            isMenuOpen={isMenuOpen}
            onClick={handleMenu}
            setMenu={setMenu}
          ></Menu>
          <Link to="/">
            <img
              src={logo}
              alt="Redsync Logo Without Moto"
              className="logo_homepage"
            />
          </Link>
          <GetAnEstimate></GetAnEstimate>
        </header>
        <main className="animation_on_load">
          <AboutUsHero></AboutUsHero>
          <AboutUsGoals></AboutUsGoals>
          <AboutUsPortfolio></AboutUsPortfolio>
        </main>
      </React.Fragment>
    </QueryClientProvider>
  );
}

export default AboutUs;
