import React from "react";
import styles from "./PageNotFound.module.css";

function PageNotFound() {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.heading}>
        <span className={styles.error}>Error:</span>
        <span className={styles.number}>404</span>
        <span className={styles.page}>Page Not Found</span>
      </h1>
    </div>
  );
}

export default PageNotFound;
