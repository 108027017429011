import React, { useEffect, useState } from "react";
import styles from "./CookiesModal.module.css";
import Cookies from "universal-cookie";
import ModalPrivacy from "./ModalPrivacy";

function CookiesModal() {
  const cookies = new Cookies();
  const [cookie, setCookie] = useState(true);
  const [modalPrivacy, setModalPrivacy] = useState();

  useEffect(() => {
    if (cookies.get("www.redsync.studio")) setCookie(false);
  }, []);

  const onClickHandler = function (e) {
    e.preventDefault();
    setModalPrivacy(true);
    document.body.style.overflow = "hidden";
  };

  const resetModalPrivacy = function (e) {
    e.preventDefault();
    setModalPrivacy(null);
    document.body.style.overflow = "unset";
  };

  const onAgree = function (e) {
    e.preventDefault();
    cookies.set("www.redsync.studio", true, {
      path: "/",
      maxAge: 63113852,
    });
    if (cookies.get("www.redsync.studio")) setCookie(false);
  };

  return (
    <React.Fragment>
      {cookie && (
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <span>
              We use cookies to personalize and optimize user experience. For
              more information, visit our&nbsp;
            </span>
            <span onClick={onClickHandler} className={styles.button_footer}>
              Privacy Policy
            </span>
            {modalPrivacy && (
              <ModalPrivacy onClick={resetModalPrivacy}></ModalPrivacy>
            )}
            <span className={styles.accept_button} onClick={onAgree}>
              I AGREE
            </span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default CookiesModal;
