import React, { useState } from "react";
import Menu from "../components/home-page/header/Menu";
import MenuButton from "../components/home-page/header/MenuButton";
import { Link } from "react-router-dom";
import Particles from "react-tsparticles";
import particlesConfig from "../config/particlesConfig";
import GetAnEstimate from "../components/helpers/GetAnEstimate";
import logo from ".././assets/logo192.png";
import PageNotFound from "../components/helpers/PageNotFound";

function NotFound() {
  const [isMenuOpen, setMenu] = useState(false);

  const handleMenu = () => {
    setMenu((prevState) => !prevState);
  };
  return (
    <React.Fragment>
      <div style={{ position: "absolute" }}>
        <Particles height="100vh" width="100vw" params={particlesConfig} />
      </div>
      <header className="header">
        <MenuButton onClick={handleMenu}></MenuButton>
        <Menu
          isMenuOpen={isMenuOpen}
          onClick={handleMenu}
          setMenu={setMenu}
        ></Menu>
        <Link to="/">
          <img
            src={logo}
            alt="Redsync Logo Without Moto"
            className="logo_homepage"
          />
        </Link>
        <GetAnEstimate></GetAnEstimate>
      </header>
      <PageNotFound></PageNotFound>
    </React.Fragment>
  );
}

export default NotFound;
