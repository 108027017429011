import React, { useState } from "react";
import Particles from "react-tsparticles";
import particlesConfig from "../config/particlesConfig";
import { Link } from "react-router-dom";
import Menu from "../components/home-page/header/Menu";
import MenuButton from "../components/home-page/header/MenuButton";
import logo from ".././assets/logo192.png";
import ContactUs from "../components/home-page/contact_us/ContactUs";
import CookiesModal from "../components/helpers/CookiesModal";

function AboutUs() {
  const [isMenuOpen, setMenu] = useState(false);

  const handleMenu = () => {
    setMenu((prevState) => !prevState);
  };

  return (
    <React.Fragment>
      <CookiesModal></CookiesModal>
      <div style={{ position: "absolute" }}>
        <Particles height="100vh" width="100vw" params={particlesConfig} />
      </div>
      <header className="header">
        <MenuButton onClick={handleMenu}></MenuButton>
        <Menu
          isMenuOpen={isMenuOpen}
          onClick={handleMenu}
          setMenu={setMenu}
        ></Menu>
        <Link to="/">
          <img
            src={logo}
            alt="Redsync Logo Without Moto"
            className="logo_homepage"
          />
        </Link>
      </header>
      <main className="animation_on_load">
        <ContactUs></ContactUs>
      </main>
    </React.Fragment>
  );
}

export default AboutUs;
