import React from "react";
import Card from "./Card";
import { Link } from "react-router-dom";

const BussinesCard = function (props) {
  return (
    <React.Fragment>
      <Link to="/business-analysis" style={{ textDecoration: "none" }}>
        <Card>
          <span>04</span>
          <h2>
            Business
            <br />
            <strong>Analysis</strong> and
            <br />
            <strong>Consulting</strong>
          </h2>
          <h3>
            #modeling
            <br />
            #improvment
            <br />
            #growth
          </h3>
        </Card>
      </Link>
    </React.Fragment>
  );
};
export default BussinesCard;
