import React from "react";
import styles from "./ScrollDown.module.css";

const ScrollDown = function (props) {
  return (
    <React.Fragment>
      <div className={styles.mouse_scroll}>
        <div className={styles.text}>
          SCROLL
          <br /> DOWN{" "}
        </div>
        <div>
          <div className={styles.mouse}>
            <div className={styles.wheel}></div>
          </div>
          <div>
            <span className={`${styles.m_scroll_arrows} ${styles.unu}`}></span>
            <span className={`${styles.m_scroll_arrows} ${styles.doi}`}></span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ScrollDown;
