import React from "react";
import styles from "./Posts.module.css";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { request, gql } from "graphql-request";
import Tilt from "react-parallax-tilt";

const endpoint1 = process.env.REACT_APP_ENDPOINT_BLOG;

const POSTS_QUERY = gql`
  query GetAllPosts {
    postsConnection(orderBy: createdAt_DESC) {
      edges {
        node {
          category {
            name
          }
          createdAt
          slug
          excerpt
          title
          featuredImage {
            url
          }
        }
      }
    }
  }
`;

function Posts() {
  const { data, isLoading, error } = useQuery("posts", () => {
    return request(endpoint1, POSTS_QUERY);
  });

  if (isLoading)
    return (
      <div className={styles.loading}>
        <span className="loader">
          <span className="loader-inner"></span>
        </span>
      </div>
    );
  if (data.postsConnection.edges.length === 0)
    return (
      <p className={styles.error_message}>
        Stay Tuned! Blog Posts Comming Soon!
      </p>
    );

  if (error)
    return <pre className={styles.error_message}>Error: No Posts Found</pre>;

  return (
    <React.Fragment>
      {data.postsConnection.edges.map((post) => (
        <article key={post.node.title} className={styles.post}>
          <Tilt scale={1.05} tiltMaxAngleX={5} tiltMaxAngleY={5}>
            <Link
              to={`/blog/post/${post.node.slug}`}
              className={styles.post_link}
            >
              <div className={styles.read_more}>
                <img
                  src={post.node.featuredImage.url}
                  alt={post.node.excerpt}
                  className={styles.post_image}
                />
                <div className={styles.center}>
                  <h2 className={styles.read_more_heading}>Read More</h2>
                </div>
              </div>
              <div className={styles.info}>
                <span className={styles.category}>
                  {post.node.category.name}
                </span>
                <span className={styles.date}>
                  {new Date(post.node.createdAt).toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                  })}
                </span>
              </div>
              <h3 className={styles.post_title}>{post.node.title}</h3>
              <p className={styles.excerpt}>{post.node.excerpt}</p>
            </Link>
          </Tilt>
        </article>
      ))}
    </React.Fragment>
  );
}

export default Posts;
