import React from "react";
import styles from "./Cards.module.css";

function Cards({ onClick, card, index, isInactive, isFlipped, isDisabled }) {
  const handleClick = () => {
    !isFlipped && !isDisabled && onClick(index);
  };

  return (
    <div
      className={`${styles.card} ${isFlipped ? styles.is_flipped : ""} ${
        isInactive ? styles.is_inactive : ""
      }`}
      onClick={handleClick}
    >
      <div className={`${styles.card_face}`}></div>
      <div className={` ${styles.card_back_face}`}>
        <img src={card.image} alt="pokeball" />
      </div>
    </div>
  );
}

export default Cards;
