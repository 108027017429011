import React from "react";
import styles from "./HeroGraphic.module.css";
import ApartmaniMihaela from "../../../assets/reference/WebDevelopment/ApartmaniMihaelaHomePage.webp";
import TopTim from "../../../assets/reference/WebDevelopment/TopTimHomePage.webp";
import ApolonShop from "../../../assets/reference/WebDevelopment/ApolonShopHomePage.webp";
import ApolonGradnja from "../../../assets/reference/WebDevelopment/ApolonGradnjaHomePage.webp";

const HeroWebsite = function (props) {
  return (
    <React.Fragment>
      <div className={styles.wrapper}>
        <h1 className={styles.heading_website_dev}>
          Graphic <br /> <strong>Design</strong> <br /> Services
        </h1>
        <span className={styles.hashes}>
          #logo
          <br />
          #brandbook
          <br />
          #packing
        </span>

        <div className={styles.slider}>
          <div className={styles.slide_track}>
            <div className={styles.slide}>
              <a href="https://top-tim.com/" target="_blank" rel="noreferrer">
                <img src={TopTim} height="auto" width="100%" alt="" />
              </a>
            </div>
            <div className={styles.slide}>
              <a
                href="https://apolonshop.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={ApolonShop} height="auto" width="100%" alt="" />
              </a>
            </div>
            <div className={styles.slide}>
              <a
                href="http://apartmanimihaela.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={ApartmaniMihaela} height="auto" width="100%" alt="" />
              </a>
            </div>
            <div className={styles.slide}>
              <a
                href="https://apolongradnja.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={ApolonGradnja} height="auto" width="100%" alt="" />
              </a>
            </div>
            <div className={styles.slide}>
              <a href="https://top-tim.com/" target="_blank" rel="noreferrer">
                <img src={TopTim} height="auto" width="100%" alt="" />
              </a>
            </div>
            <div className={styles.slide}>
              <a
                href="https://apolonshop.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={ApolonShop} height="auto" width="100%" alt="" />
              </a>
            </div>
            <div className={styles.slide}>
              <a
                href="http://apartmanimihaela.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={ApartmaniMihaela} height="auto" width="100%" alt="" />
              </a>
            </div>
            <div className={styles.slide}>
              <a
                href="https://apolongradnja.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={ApolonGradnja} height="auto" width="100%" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeroWebsite;
