import { RichText } from "@graphcms/rich-text-react-renderer";
import "prismjs/plugins/line-numbers/prism-line-numbers";
import "prismjs/plugins/line-numbers/prism-line-numbers.css";
import "prismjs/themes/prism-tomorrow.css";
import React from "react";
import CodeBlock from "./CodeBlock";
import styles from "./SinglePost.module.css";

const SinglePostContent = ({ post }) => {
  return (
    <>
      {!!post.content && (
        <RichText
          content={post.content.raw}
          renderers={{
            h3: ({ children }) => (
              <h3 className={styles.post_content_h3}>{children}</h3>
            ),
            h4: ({ children }) => (
              <h4 className={styles.post_content_h4}>{children}</h4>
            ),
            h5: ({ children }) => (
              <h5 className={styles.post_content_h4}>{children}</h5>
            ),
            p: ({ children }) => (
              <p className={styles.post_content_p}>{children}</p>
            ),
            a: ({ href, title }) => (
              <a
                className={styles.post_content_a}
                href={href}
                target="_blank"
                rel="noreferrer"
              >
                {!!title ? title : href}
              </a>
            ),
            code_block: ({ children }) => <CodeBlock>{children}</CodeBlock>,
            img: ({ src, altText, height, width }) => (
              <img
                alt={altText}
                height={height}
                width={width}
                src={src}
                layout="fill"
                objectFit="cover"
                className={styles.post_content_img}
              />
            ),
            bold: ({ children }) => <strong>{children}</strong>,
            italic: ({ children }) => <em>{children}</em>,
            underline: ({ children }) => <u>{children}</u>,
          }}
        />
      )}
    </>
  );
};

export default SinglePostContent;
