import React from "react";
import styles from "./PortfolioLink.module.css";
import { useQuery } from "react-query";
import { request, gql } from "graphql-request";

const endpoint = process.env.REACT_APP_ENDPOINT_PORTFOLIO;

const WEB_PORTFOLIO_QUERY = gql`
  query MyQuery {
    references(where: { categories_some: { name: "Digital Marketing" } }) {
      referenceId
      id
      name
      image {
        url
      }
      hashes
      url
    }
  }
`;

const PortfolioLink = function (props) {
  const { data, isLoading, error } = useQuery("posts", () => {
    return request(endpoint, WEB_PORTFOLIO_QUERY);
  });

  if (isLoading) return <span className={styles.loading}>Loading...</span>;
  if (error)
    return (
      <pre className={styles.error_message}>Error: No References Found</pre>
    );

  return (
    <React.Fragment>
      {data.references.map((reference) => {
        return (
          <li key={reference.id} className={styles.link_wrapper}>
            <a
              href={reference.url}
              target="_blank"
              className={styles.link}
              rel="noreferrer"
            >
              <span className={styles.span}>{reference.referenceId}</span>
              <h3 className={styles.name}>{reference.name}</h3>
              <span className={styles.hash}>{reference.hashes}</span>
            </a>
            <img
              src={reference.image.url}
              className={styles.image}
              alt={`Home page of ${reference.name}`}
            />
          </li>
        );
      })}
    </React.Fragment>
  );
};

export default PortfolioLink;
